<template>
    <h1 style="color:#035c34;">Fuel Purchasing Information</h1>
    <h4 style="margin-bottom:0.5em;color:#035c34;">Please enter how much fuel has been purchased in litres for each type of fuel.*</h4><br /><br />
            <br />
            <v-row>
                <v-col style="height:3.5rem;line-height:3.5rem;font-weight:bold">Petrol</v-col>
                <v-col><v-text-field v-mask="['#', '##', '###', '#,###', '##,###', '###,###', '#,###,###', '##,###,###', '###,###,###', '#,###,###,###', '##,###,###,###', '###,###,###,###', '#,###,###,###,###']" masked="true"  v-model="formData.litres_purchased_fuel.petrol" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="No." placeholder="No." required></v-text-field></v-col>
            </v-row>
            <v-row>
                <v-col style="height:3.5rem;line-height:3.5rem;font-weight:bold">Diesel</v-col>
                <v-col><v-text-field v-mask="['#', '##', '###', '#,###', '##,###', '###,###', '#,###,###', '##,###,###', '###,###,###', '#,###,###,###', '##,###,###,###', '###,###,###,###', '#,###,###,###,###']" masked="true"  v-model="formData.litres_purchased_fuel.diesel" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="No." placeholder="No." required></v-text-field></v-col>
            </v-row>
            <v-row>
                <v-col style="height:3.5rem;line-height:3.5rem;font-weight:bold">Mixed Petrol/Diesel</v-col>
                <v-col><v-text-field v-mask="['#', '##', '###', '#,###', '##,###', '###,###', '#,###,###', '##,###,###', '###,###,###', '#,###,###,###', '##,###,###,###', '###,###,###,###', '#,###,###,###,###']" masked="true"  v-model="formData.litres_purchased_fuel.mixed" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="No." placeholder="No." required></v-text-field></v-col>
            </v-row>
            <v-row>
                <v-col style="height:3.5rem;line-height:3.5rem;font-weight:bold">Hybrid</v-col>
                <v-col><v-text-field v-mask="['#', '##', '###', '#,###', '##,###', '###,###', '#,###,###', '##,###,###', '###,###,###', '#,###,###,###', '##,###,###,###', '###,###,###,###', '#,###,###,###,###']" masked="true"  v-model="formData.litres_purchased_fuel.hybrid" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="No." placeholder="No." required></v-text-field></v-col>
            </v-row>
            <v-row>
                <v-col style="height:3.5rem;line-height:3.5rem;font-weight:bold">Aviation Fuel</v-col>
                <v-col><v-text-field v-mask="['#', '##', '###', '#,###', '##,###', '###,###', '#,###,###', '##,###,###', '###,###,###', '#,###,###,###', '##,###,###,###', '###,###,###,###', '#,###,###,###,###']" masked="true"  v-model="formData.litres_purchased_fuel.aviation_fuel" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="No." placeholder="No." required></v-text-field></v-col>
            </v-row>
            <v-row>
                <v-col style="height:3.5rem;line-height:3.5rem;font-weight:bold">Electricity (KWH)</v-col>
                <v-col><v-text-field v-mask="['#', '##', '###', '#,###', '##,###', '###,###', '#,###,###', '##,###,###', '###,###,###', '#,###,###,###', '##,###,###,###', '###,###,###,###', '#,###,###,###,###']" masked="true"  v-model="formData.litres_purchased_fuel.electricity" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="No." placeholder="No." required></v-text-field></v-col>
            </v-row>
            <br />
          <h4 style="margin-bottom:0.5em;color:#035c34;">Where litres purchased is not available, please enter the amount of re-imbursed miles for each vehicle type.*</h4><br /><br />
            <v-row>
                <v-col style="height:3.5rem;line-height:3.5rem;font-weight:bold">Motorbike</v-col>
                <v-col><v-text-field v-mask="['#', '##', '###', '#,###', '##,###', '###,###', '#,###,###', '##,###,###', '###,###,###', '#,###,###,###', '##,###,###,###', '###,###,###,###', '#,###,###,###,###']" masked="true"  v-model="formData.reimbursed_miles.motorbike" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="No." placeholder="No." required></v-text-field></v-col>
            </v-row>
            <v-row>
                <v-col style="height:3.5rem;line-height:3.5rem;font-weight:bold">Company Car</v-col>
                <v-col><v-text-field v-mask="['#', '##', '###', '#,###', '##,###', '###,###', '#,###,###', '##,###,###', '###,###,###', '#,###,###,###', '##,###,###,###', '###,###,###,###', '#,###,###,###,###']" masked="true"  v-model="formData.reimbursed_miles.company_car" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="No." placeholder="No." required></v-text-field></v-col>
            </v-row>
            <v-row>
                <v-col style="height:3.5rem;line-height:3.5rem;font-weight:bold">4X4 / SUV</v-col>
                <v-col><v-text-field v-mask="['#', '##', '###', '#,###', '##,###', '###,###', '#,###,###', '##,###,###', '###,###,###', '#,###,###,###', '##,###,###,###', '###,###,###,###', '#,###,###,###,###']" masked="true"  v-model="formData.reimbursed_miles['4x4_suv']" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="No." placeholder="No." required></v-text-field></v-col>
            </v-row>
            <v-row>
                <v-col style="height:3.5rem;line-height:3.5rem;font-weight:bold">MPV / Mini Bus</v-col>
                <v-col><v-text-field v-mask="['#', '##', '###', '#,###', '##,###', '###,###', '#,###,###', '##,###,###', '###,###,###', '#,###,###,###', '##,###,###,###', '###,###,###,###', '#,###,###,###,###']" masked="true"  v-model="formData.reimbursed_miles.mpv_minibus" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="No." placeholder="No." required></v-text-field></v-col>
            </v-row>
            <v-row>
                <v-col style="height:3.5rem;line-height:3.5rem;font-weight:bold">Van</v-col>
                <v-col><v-text-field v-mask="['#', '##', '###', '#,###', '##,###', '###,###', '#,###,###', '##,###,###', '###,###,###', '#,###,###,###', '##,###,###,###', '###,###,###,###', '#,###,###,###,###']" masked="true"  v-model="formData.reimbursed_miles.van" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="No." placeholder="No." required></v-text-field></v-col>
            </v-row>
            <br />
            
          <v-btn block size="x-large" color="#7aaa3c" :disabled="!isEnergySpendValid" @click="proceedApplication">Next</v-btn>
          <v-btn block size="x-large" @click="$emit('back')">Back</v-btn>
</template>
<script>
import axios from "axios"
export default {
    name: "EnergySpendInformation",
    data() {
        return {
            formData: {
                'reimbursed_miles': {
                    '4x4_suv': '',
                    'company_car': '',
                    'motorbike': '',
                    'mpv_minibus': '',
                    'van': '',
                },
                'litres_purchased_fuel': {
                    'aviation_fuel': '',
                    'diesel': '',
                    'electricity': '',
                    'hybrid': '',
                    'mixed': '',
                    'petrol': '',
                    'total': '',
                },
            },
        }
    },
    props: {
        username: null,
        password: null,
        data: null,
        pageno: null,
    },
    methods: {
        proceedApplication() {
            axios.post("https://carbon-application-flask.nw.r.appspot.com/store_data", Object.assign({current_page_no: this.pageno}, this.formData, {username: this.username, password: this.password})) .then((r) => {
                if(r.data.message === "Data successfully updated") {
                    this.$emit('finish', this.formData)
                } else {
                    alert("Something went wrong, please check your information.")
                }
            }) .catch((e) => {
                console.log(e)
            })
        }
    },
    computed: {
        isEnergySpendValid() {
            return (
                this.formData.reimbursed_miles['4x4_suv'] &&
                this.formData.reimbursed_miles.company_car &&
                this.formData.reimbursed_miles.motorbike &&
                this.formData.reimbursed_miles.mpv_minibus &&
                this.formData.reimbursed_miles.van &&
                this.formData.litres_purchased_fuel.aviation_fuel &&
                this.formData.litres_purchased_fuel.diesel &&
                this.formData.litres_purchased_fuel.electricity &&
                this.formData.litres_purchased_fuel.hybrid &&
                this.formData.litres_purchased_fuel.mixed &&
                this.formData.litres_purchased_fuel.petrol
            )
        }
    },
    beforeMount() {
        this.formData = {
            'reimbursed_miles': {
                    '4x4_suv': this.data.reimbursed_miles['4x4_suv'],
                    'company_car': this.data.reimbursed_miles.company_car,
                    'motorbike': this.data.reimbursed_miles.motorbike,
                    'mpv_minibus': this.data.reimbursed_miles.mpv_minibus,
                    'van': this.data.reimbursed_miles.van,
                },
                'litres_purchased_fuel': {
                    'aviation_fuel': this.data.litres_purchased_fuel.aviation_fuel,
                    'diesel': this.data.litres_purchased_fuel.diesel,
                    'electricity': this.data.litres_purchased_fuel.electricity,
                    'hybrid': this.data.litres_purchased_fuel.hybrid,
                    'mixed': this.data.litres_purchased_fuel.mixed,
                    'petrol': this.data.litres_purchased_fuel.petrol,
                    'total': '',
                },
        }
        if(this.formData.reimbursed_miles['4x4_suv'] &&
                this.formData.reimbursed_miles.company_car &&
                this.formData.reimbursed_miles.motorbike &&
                this.formData.reimbursed_miles.mpv_minibus &&
                this.formData.reimbursed_miles.van &&
                this.formData.litres_purchased_fuel.aviation_fuel &&
                this.formData.litres_purchased_fuel.diesel &&
                this.formData.litres_purchased_fuel.electricity &&
                this.formData.litres_purchased_fuel.hybrid &&
                this.formData.litres_purchased_fuel.mixed &&
                this.formData.litres_purchased_fuel.petrol) {
            this.$emit('finish', this.formData)
        }
    }
}
</script>