<template>
    <h1 style="color:#035c34;">Management Representative</h1>
          <small style="margin-bottom:2em">This next section is a form about your role as a management representative.</small><br /><br />
            <h4 style="margin-bottom:0.5em;color:#035c34;">How many employees (%) worked from home over the last year? <v-text-field v-model="formData.management_representative_data.work_from_home_percentage" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="#" placeholder="#" required></v-text-field></h4>
            <br />
          <h4 style="margin-bottom:0.5em;color:#035c34;">For each of these time periods, please enter what percentage of your employees work from home.</h4>
          <v-row>
                <v-col style="height:3.5rem;line-height:3.5rem;">5 days/week</v-col>
                <v-col><v-text-field v-mask="['#%', '##%', '###%']" masked="true"  v-model="formData.management_representative_data.employee_wfh['5_day_week']" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="%" placeholder="%" required></v-text-field></v-col>
            </v-row>
            <v-row>
                <v-col style="height:3.5rem;line-height:3.5rem;">4 days/week</v-col>
                <v-col><v-text-field v-mask="['#%', '##%', '###%']" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="%" placeholder="%" required></v-text-field></v-col>
            </v-row>
            <v-row>
                <v-col style="height:3.5rem;line-height:3.5rem;">3 days/week</v-col>
                <v-col><v-text-field v-mask="['#%', '##%', '###%']" masked="true"  v-model="formData.management_representative_data.employee_wfh['3_day_week']" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="%" placeholder="%" required></v-text-field></v-col>
            </v-row>
            <v-row>
                <v-col style="height:3.5rem;line-height:3.5rem;">2 days/week</v-col>
                <v-col><v-text-field v-mask="['#%', '##%', '###%']" masked="true"  v-model="formData.management_representative_data.employee_wfh['2_day_week']" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="%" placeholder="%" required></v-text-field></v-col>
            </v-row>
            <v-row>
                <v-col style="height:3.5rem;line-height:3.5rem;">1 day/week</v-col>
                <v-col><v-text-field v-mask="['#%', '##%', '###%']" masked="true"  v-model="formData.management_representative_data.employee_wfh['1_day_week']" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="%" placeholder="%" required></v-text-field></v-col>
            </v-row>
            <br />
          <h4 style="margin-bottom:0.5em;color:#035c34;">In total, how much of each unit did you use? <i>(These fields are optional, if you leave them blank we will calculate these for you.)</i></h4>
          <v-row>
            <v-col style="height:3.5rem;line-height:3.5rem;">kWh</v-col>
            <v-col><v-text-field v-model="formData.management_representative_data.totals.total_kwh" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="Amount" placeholder="Amount" required></v-text-field></v-col>
            <v-col style="height:3.5rem;line-height:3.5rem;">Total £</v-col>
            <v-col><v-text-field v-model="formData.management_representative_data.totals.total_gbp" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="Amount" placeholder="Amount" required></v-text-field></v-col>
          </v-row>
          <v-row>
            <v-col style="height:3.5rem;line-height:3.5rem;">Litres</v-col>
            <v-col><v-text-field v-model="formData.management_representative_data.totals.total_litres" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="Amount" placeholder="Amount" required></v-text-field></v-col>
            <v-col style="height:3.5rem;line-height:3.5rem;">kgCO₂e</v-col>
            <v-col><v-text-field v-model="formData.management_representative_data.totals.total_kgco2e" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="Amount" placeholder="Amount" required></v-text-field></v-col>
        </v-row>
            <v-btn block size="x-large" color="#7aaa3c" :disabled="!isEnergySpendValid" @click="proceedApplication">Next</v-btn>
            <v-btn block size="x-large" @click="$emit('back')">Back</v-btn>
</template>
<script>
import axios from "axios"
export default {
    name: "EnergySpendInformation",
    data() {
        return {
            formData: {
                'management_representative_data': {
                    'employee_wfh': {
                        '1_day_week': '',
                        '2_day_week': '',
                        '3_day_week': '',
                        '4_day_week': '',
                        '5_day_week': '',
                    },
                    'totals': {
                        'total_gbp': '',
                        'total_kgco2e': '',
                        'total_kwh': '',
                        'total_litres': '',
                    },
                    'work_from_home_percentage': ''
                },
            },
        }
    },
    props: {
        username: null,
        password: null,
        data: null,
        pageno: null,
    },
    methods: {
        proceedApplication() {
            axios.post("https://carbon-application-flask.nw.r.appspot.com/store_data", Object.assign({current_page_no: this.pageno}, this.formData, {username: this.username, password: this.password})) .then((r) => {
                if(r.data.message === "Data successfully updated") {
                    this.$emit('finish', this.formData)
                } else {
                    alert("Something went wrong, please check your information.")
                }
            }) .catch((e) => {
                console.log(e)
            })
        }
    },
    computed: {
        isEnergySpendValid() {
            return (
                (this.formData.management_representative_data.employee_wfh['5_day_week'] ||
                this.formData.management_representative_data.employee_wfh['4_day_week'] ||
                this.formData.management_representative_data.employee_wfh['3_day_week'] ||
                this.formData.management_representative_data.employee_wfh['2_day_week'] ||
                this.formData.management_representative_data.employee_wfh['1_day_week']) &&
                this.formData.management_representative_data.work_from_home_percentage
            )
        }
    },
    beforeMount() {
        this.formData = {
            'management_representative_data': {
                    'employee_wfh': {
                        '1_day_week': this.data.management_representative_data.employee_wfh['1_day_week'],
                        '2_day_week': this.data.management_representative_data.employee_wfh['2_day_week'],
                        '3_day_week': this.data.management_representative_data.employee_wfh['3_day_week'],
                        '4_day_week': this.data.management_representative_data.employee_wfh['4_day_week'],
                        '5_day_week': this.data.management_representative_data.employee_wfh['5_day_week'],
                    },
                    'totals': {
                        'total_gbp': this.data.management_representative_data.totals.total_gbp,
                        'total_kgco2e': this.data.management_representative_data.totals.total_kgco2e,
                        'total_kwh': this.data.management_representative_data.totals.total_kwh,
                        'total_litres': this.data.management_representative_data.totals.total_litres,
                    },
                    'work_from_home_percentage': this.data.management_representative_data.work_from_home_percentage
                },
        }
        if((this.formData.management_representative_data.employee_wfh['5_day_week'] ||
                this.formData.management_representative_data.employee_wfh['4_day_week'] ||
                this.formData.management_representative_data.employee_wfh['3_day_week'] ||
                this.formData.management_representative_data.employee_wfh['2_day_week'] ||
                this.formData.management_representative_data.employee_wfh['1_day_week']) &&
                this.formData.management_representative_data.work_from_home_percentage) {
            this.$emit('finish', this.formData)
        }
    }
}
</script>