<template>
    <h1 style="color:#035c34;">Position Information</h1>
          <small style="margin-bottom:2em">This next section can be completed from 2 different positions. Please check the positions that apply to you.</small><br /><br />
            <v-checkbox label="Employee" v-model="formData.company_positions" value="Employee"></v-checkbox>
            <v-checkbox label="Management Representative" v-model="formData.company_positions" value="Management Representative" ></v-checkbox>
            <v-btn block size="x-large" color="#7aaa3c" :disabled="!isEnergySpendValid" @click="proceedApplication">Next</v-btn>
            <v-btn block size="x-large" @click="$emit('back')">Back</v-btn>
</template>
<script>
import axios from "axios"
export default {
    name: "EnergySpendInformation",
    data() {
        return {
            formData: {
                company_positions: []
            },
        }
    },
    methods: {
        proceedApplication() {
            axios.post("https://carbon-application-flask.nw.r.appspot.com/store_data", Object.assign({current_page_no: this.pageno}, this.formData, {username: this.username, password: this.password})) .then((r) => {
                if(r.data.message === "Data successfully updated") {
                    this.$emit('finish', this.formData)
                } else {
                    alert("Something went wrong, please check your information.")
                }
            }) .catch((e) => {
                console.log(e)
            })
        }
    },
    props: {
        username: null,
        password: null,
        data: null,
        pageno: null,
    },
    computed: {
        isEnergySpendValid() {
            return (
                this.formData.company_positions[0]
            )
        }
    },
    beforeMount() {
        this.formData = {
            company_positions: this.data.company_positions[0] === "" ? [] : this.data.company_positions
        }
        if(this.formData.company_positions[0]) {
            this.$emit('finish', this.formData)
        }
    }
}
</script>