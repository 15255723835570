<template>
    <h1 style="color:#035c34;">Fleet & Field Information</h1>
          <small style="margin-bottom:2em">For fleet & field, please enter total values for the following fields.* If you are unsure of any of the values, set the number to 0 and this application will calculate this data from the information provided.</small><br /><br />
            <v-row>
                <v-col style="height:3.5rem;line-height:3.5rem;font-weight:bold">Total Miles <small style="color:#b8b8b8">(Enter the total distance traveled by your entire fleet in miles)</small></v-col>
                <v-col><v-text-field v-model="formData.fleet_field_totals.miles" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="No." placeholder="No." required></v-text-field></v-col>
            </v-row>
            <v-row>
                <v-col style="height:3.5rem;line-height:3.5rem;font-weight:bold">Total Litres <small style="color:#b8b8b8">(Enter the total amount of fuel consumed by your fleet in litres)</small></v-col>
                <v-col><v-text-field v-model="formData.fleet_field_totals.litres" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="No." placeholder="No." required></v-text-field></v-col>
            </v-row>
            <v-row>
                <v-col style="height:3.5rem;line-height:3.5rem;font-weight:bold">Total kWh <small style="color:#b8b8b8">(Enter the total amount of electricity used by your electric vehicles in kilowatt-hours)</small></v-col>
                <v-col><v-text-field v-model="formData.fleet_field_totals.kwh" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="No." placeholder="No." required></v-text-field></v-col>
            </v-row>
            <v-row>
                <v-col style="height:3.5rem;line-height:3.5rem;font-weight:bold">Total Cost (£GBP) <small style="color:#b8b8b8">(Enter the total cost of fuel and electricity for your fleet in British pounds )</small></v-col>
                <v-col><v-text-field v-model="formData.fleet_field_totals.cost" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="No." placeholder="No." required></v-text-field></v-col>
            </v-row>
            <v-row>
                <v-col style="height:3.5rem;line-height:3.5rem;font-weight:bold">Total kgCO₂e <small style="color:#b8b8b8">(Enter the estimated total kilogram of CO2 equivalent (kgCO2e) emissions generated by your fleet)</small></v-col>
                <v-col><v-text-field v-model="formData.fleet_field_totals.kgco2e" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="No." placeholder="No." required></v-text-field></v-col>
            </v-row>
            
          <v-btn block size="x-large" color="#7aaa3c" :disabled="!isEnergySpendValid" @click="proceedApplication">Next</v-btn>
          <v-btn block size="x-large" @click="$emit('back')">Back</v-btn>
</template>
<script>
import axios from "axios"
export default {
    name: "EnergySpendInformation",
    data() {
        return {
            formData: {
                'fleet_field_totals': {
                    'cost': '',
                    'kgco2e': '',
                    'kwh': '',
                    'litres': '',
                    'miles': '',
                },
            },
        }
    },
    props: {
        username: null,
        password: null,
        data: null,
        pageno: null,
    },
    methods: {
        proceedApplication() {
            axios.post("https://carbon-application-flask.nw.r.appspot.com/store_data", Object.assign({current_page_no: this.pageno}, this.formData, {username: this.username, password: this.password})) .then((r) => {
                if(r.data.message === "Data successfully updated") {
                    this.$emit('finish', this.formData)
                } else {
                    alert("Something went wrong, please check your information.")
                }
            }) .catch((e) => {
                console.log(e)
            })
        }
    },
    computed: {
        isEnergySpendValid() {
            return (
                this.formData.fleet_field_totals.cost &&
                this.formData.fleet_field_totals.kgco2e &&
                this.formData.fleet_field_totals.kwh &&
                this.formData.fleet_field_totals.litres &&
                this.formData.fleet_field_totals.miles 
            )
        }
    },
    beforeMount() {
        this.formData = {
            'fleet_field_totals': {
                    'cost': this.data.fleet_field_totals.cost,
                    'kgco2e': this.data.fleet_field_totals.kgco2e,
                    'kwh': this.data.fleet_field_totals.kwh,
                    'litres': this.data.fleet_field_totals.litres,
                    'miles': this.data.fleet_field_totals.miles,
                },
        }
        if(this.formData.fleet_field_totals.cost &&
                this.formData.fleet_field_totals.kgco2e &&
                this.formData.fleet_field_totals.kwh &&
                this.formData.fleet_field_totals.litres &&
                this.formData.fleet_field_totals.miles ) {
            this.$emit('finish', this.formData)
        }
    }
}
</script>