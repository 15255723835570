<template>
    <h1 style="color:#035c34;">Floor Area Information</h1>
          <small style="margin-bottom:2em">Please enter the total amount of floor area owned (square meters) for each type of premises. This page is optional.</small><br /><br />
            <v-row>
                <v-col style="height:3.5rem;line-height:3.5rem;">Administrative Office</v-col>
                <v-col><v-text-field v-mask="['#', '##', '###', '#,###', '##,###', '###,###', '#,###,###', '##,###,###', '###,###,###', '#,###,###,###', '##,###,###,###', '###,###,###,###', '#,###,###,###,###']" masked="true"  v-model="formData.floor_area.administrativeoffice" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="m²" placeholder="m²" required></v-text-field></v-col>
            </v-row>
            <v-row>
                <v-col style="height:3.5rem;line-height:3.5rem;">Manufacturing</v-col>
                <v-col><v-text-field v-mask="['#', '##', '###', '#,###', '##,###', '###,###', '#,###,###', '##,###,###', '###,###,###', '#,###,###,###', '##,###,###,###', '###,###,###,###', '#,###,###,###,###']" masked="true"  v-model="formData.floor_area.manufacturing" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="m²" placeholder="m²" required></v-text-field></v-col>
            </v-row>
            <v-row>
                <v-col style="height:3.5rem;line-height:3.5rem;">Warehousing</v-col>
                <v-col><v-text-field v-mask="['#', '##', '###', '#,###', '##,###', '###,###', '#,###,###', '##,###,###', '###,###,###', '#,###,###,###', '##,###,###,###', '###,###,###,###', '#,###,###,###,###']" masked="true"  v-model="formData.floor_area.warehousing" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="m²" placeholder="m²" required></v-text-field></v-col>
            </v-row>
            <v-row>
                <v-col style="height:3.5rem;line-height:3.5rem;">Retail</v-col>
                <v-col><v-text-field v-mask="['#', '##', '###', '#,###', '##,###', '###,###', '#,###,###', '##,###,###', '###,###,###', '#,###,###,###', '##,###,###,###', '###,###,###,###', '#,###,###,###,###']" masked="true"  v-model="formData.floor_area.retail" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="m²" placeholder="m²" required></v-text-field></v-col>
            </v-row>
            <v-row>
                <v-col style="height:3.5rem;line-height:3.5rem;">Accommodation</v-col>
                <v-col><v-text-field v-mask="['#', '##', '###', '#,###', '##,###', '###,###', '#,###,###', '##,###,###', '###,###,###', '#,###,###,###', '##,###,###,###', '###,###,###,###', '#,###,###,###,###']" masked="true"  v-model="formData.floor_area.accomodation" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="m²" placeholder="m²" required></v-text-field></v-col>
            </v-row>
            <v-row>
                <v-col style="height:3.5rem;line-height:3.5rem;">Workshop</v-col>
                <v-col><v-text-field v-mask="['#', '##', '###', '#,###', '##,###', '###,###', '#,###,###', '##,###,###', '###,###,###', '#,###,###,###', '##,###,###,###', '###,###,###,###', '#,###,###,###,###']" masked="true"  v-model="formData.floor_area.workshop" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="m²" placeholder="m²" required></v-text-field></v-col>
            </v-row>
            <v-row>
                <v-col style="height:3.5rem;line-height:3.5rem;">Laboratory/Processing</v-col>
                <v-col><v-text-field v-mask="['#', '##', '###', '#,###', '##,###', '###,###', '#,###,###', '##,###,###', '###,###,###', '#,###,###,###', '##,###,###,###', '###,###,###,###', '#,###,###,###,###']" masked="true"  v-model="formData.floor_area.labprocessing" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="m²" placeholder="m²" required></v-text-field></v-col>
            </v-row>
            <v-row>
                <v-col style="height:3.5rem;line-height:3.5rem;">Lecture Hall/Theatre</v-col>
                <v-col><v-text-field v-mask="['#', '##', '###', '#,###', '##,###', '###,###', '#,###,###', '##,###,###', '###,###,###', '#,###,###,###', '##,###,###,###', '###,###,###,###', '#,###,###,###,###']" masked="true"  v-model="formData.floor_area.lecturehalltheatre" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="m²" placeholder="m²" required></v-text-field></v-col>
            </v-row>
            <v-row>
                <v-col style="height:3.5rem;line-height:3.5rem;">Food Servery</v-col>
                <v-col><v-text-field v-mask="['#', '##', '###', '#,###', '##,###', '###,###', '#,###,###', '##,###,###', '###,###,###', '#,###,###,###', '##,###,###,###', '###,###,###,###', '#,###,###,###,###']" masked="true"  v-model="formData.floor_area.foodservery" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="m²" placeholder="m²" required></v-text-field></v-col>
            </v-row>
            <v-row>
                <v-col style="height:3.5rem;line-height:3.5rem;font-weight:bold">Total</v-col>
                
                <v-col><v-text-field disabled v-model="totalNumber" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="m²" placeholder="m²" required></v-text-field></v-col>
               
            </v-row>
           
          <v-btn block size="x-large" color="#7aaa3c" @click="proceedApplication">Next</v-btn>
          <v-btn block size="x-large" @click="$emit('back')">Back</v-btn>
</template>
<script>
import axios from "axios"
export default {
    name: "FloorAreaInformation",
    data() {
        return {
            formData: {
                floor_area: {
                    'accomodation': 0,
                    'administrativeoffice': 0,
                    'labprocessing': 0,
                    'lecturehalltheatre': 0,
                    'manufacturing': 0,
                    'retail': 0,
                    'foodservery': 0,
                    'warehousing': 0,
                    'workshop': 0,
                    'total': 0
                }
                
            },
        }
    },
    props: {
        username: null,
        password: null,
        data: null,
        pageno: null,
    },
    methods: {
        proceedApplication() {
            this.formData.floor_area.total = this.totalNumber
            axios.post("https://carbon-application-flask.nw.r.appspot.com/store_data", Object.assign({current_page_no: this.pageno}, this.formData, {username: this.username, password: this.password})) .then((r) => {
                if(r.data.message === "Data successfully updated") {
                    this.$emit('finish', this.formData)
                } else {
                    alert("Something went wrong, please check your information.")
                }
            }) .catch((e) => {
                console.log(e)
            })
        }
    },
    
    computed: {
        totalNumber() {
            return parseInt(this.formData.floor_area.accomodation.toString().replace(/,/g, "")) + parseInt(this.formData.floor_area.administrativeoffice.toString().replace(/,/g, "")) + parseInt(this.formData.floor_area.labprocessing.toString().replace(/,/g, "")) + parseInt(this.formData.floor_area.lecturehalltheatre.toString().replace(/,/g, "")) + parseInt(this.formData.floor_area.manufacturing.toString().replace(/,/g, "")) + parseInt(this.formData.floor_area.retail.toString().replace(/,/g, "")) + parseInt(this.formData.floor_area.foodservery.toString().replace(/,/g, "")) + parseInt(this.formData.floor_area.warehousing.toString().replace(/,/g, "")) + parseInt(this.formData.floor_area.workshop.toString().replace(/,/g, ""))
        }
    },
    beforeMount() {
        this.formData = {
            floor_area: {
                    'accomodation': this.data.floor_area.accomodation,
                    'administrativeoffice': this.data.floor_area.administrativeoffice,
                    'labprocessing': this.data.floor_area.labprocessing,
                    'lecturehalltheatre': this.data.floor_area.lecturehalltheatre,
                    'manufacturing': this.data.floor_area.manufacturing,
                    'retail': this.data.floor_area.retail,
                    'foodservery': this.data.floor_area.foodservery,
                    'warehousing': this.data.floor_area.warehousing,
                    'workshop': this.data.floor_area.workshop,
                    'total': this.data.floor_area.total
                }
        }
        if(this.formData.floor_area.accomodation &&
        this.formData.floor_area.administrativeoffice &&
        this.formData.floor_area.labprocessing &&
        this.formData.floor_area.lecturehalltheatre &&
        this.formData.floor_area.manufacturing &&
        this.formData.floor_area.retail &&
        this.formData.floor_area.foodservery &&
        this.formData.floor_area.warehousing &&
        this.formData.floor_area.workshop &&
        this.formData.floor_area.total
        ) {
            this.$emit('finish', this.formData)
        }
    }
}
</script>