<template>
    <h1 style="color:#035c34;">Employee</h1>
   
          <small style="margin-bottom:2em">This next section can be completed from 2 different positions. Please check the positions that apply to you.</small><br /><br />
            
          <h4 style="margin-bottom:0.5em;color:#035c34;">How often (%) did you work from home last year? <v-text-field v-model="formData.employee_data.work_from_home_percentage" style="border-radius:1em;margin-bottom:0.1em;width:20vw" variant="outlined" color="#7aaa3c" label="%" placeholder="%" required></v-text-field></h4>
            <br />
          <h4 style="margin-bottom:0.5em;color:#035c34;">Did you use any of the following?</h4>
          <v-checkbox label="Electricity" v-model="formData.employee_data.resources_used" value="Electricity"></v-checkbox>
            <v-checkbox label="Natural Gas" v-model="formData.employee_data.resources_used" value="Natural Gas" ></v-checkbox>
            <v-checkbox label="Burning Oil/Kerosene" v-model="formData.employee_data.resources_used" value="Burning Oil/Kerosene"></v-checkbox>
            <v-checkbox label="Gas Oil" v-model="formData.employee_data.resources_used" value="Gas Oil" ></v-checkbox>
            <v-checkbox label="Water" v-model="formData.employee_data.resources_used" value="Water"></v-checkbox>
            <br />
          <h4 style="margin-bottom:0.5em;color:#035c34;">In total, how much of each unit did you use?</h4>
          <v-row>
            <v-col style="height:3.5rem;line-height:3.5rem;">kWh</v-col>
            <v-col><v-text-field v-mask="['#', '##', '###', '#,###', '##,###', '###,###', '#,###,###', '##,###,###', '###,###,###', '#,###,###,###', '##,###,###,###', '###,###,###,###', '#,###,###,###,###']" masked="true"  v-model="formData.employee_data.totals.total_kwh" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="Amount" placeholder="Amount" required></v-text-field></v-col>
            <v-col style="height:3.5rem;line-height:3.5rem;">Total £</v-col>
            <v-col><v-text-field v-mask="['#', '##', '###', '#,###', '##,###', '###,###', '#,###,###', '##,###,###', '###,###,###', '#,###,###,###', '##,###,###,###', '###,###,###,###', '#,###,###,###,###']" masked="true"  v-model="formData.employee_data.totals.total_gbp" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="Amount" placeholder="Amount" required></v-text-field></v-col>
          </v-row>
          <v-row>
            <v-col style="height:3.5rem;line-height:3.5rem;">Litres</v-col>
            <v-col><v-text-field v-mask="['#', '##', '###', '#,###', '##,###', '###,###', '#,###,###', '##,###,###', '###,###,###', '#,###,###,###', '##,###,###,###', '###,###,###,###', '#,###,###,###,###']" masked="true"  v-model="formData.employee_data.totals.total_litres" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="Amount" placeholder="Amount" required></v-text-field></v-col>
            <v-col style="height:3.5rem;line-height:3.5rem;">kgCO₂e</v-col>
            <v-col><v-text-field v-model="formData.employee_data.totals.total_kgco2e" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="Amount" placeholder="Amount" required></v-text-field></v-col>
        </v-row>
            <v-btn block size="x-large" color="#7aaa3c" :disabled="!isEnergySpendValid" @click="proceedApplication">Next</v-btn>
            <v-btn block size="x-large" @click="$emit('back')">Back</v-btn>
</template>
<script>
import axios from "axios"
export default {
    name: "EnergySpendInformation",
    data() {
        return {
            formData: {
                'employee_data': {
                'resources_used': [],
                'totals': {
                'total_gbp': '',
                'total_kgco2e': '',
                'total_kwh': '',
                'total_litres': '',
                },
                'work_from_home_percentage': ''
            },
            },
        }
    },
    props: {
        username: null,
        password: null,
        data: null,
        pageno: null,
    },
    methods: {
        proceedApplication() {
            axios.post("https://carbon-application-flask.nw.r.appspot.com/store_data", Object.assign({current_page_no: this.pageno}, this.formData, {username: this.username, password: this.password})) .then((r) => {
                if(r.data.message === "Data successfully updated") {
                    this.$emit('finish', this.formData)
                } else {
                    alert("Something went wrong, please check your information.")
                }
            }) .catch((e) => {
                console.log(e)
            })
        }
    },
    computed: {
        isEnergySpendValid() {
            return (
                this.formData.employee_data.resources_used[0] &&
                this.formData.employee_data.totals.total_gbp &&
                this.formData.employee_data.totals.total_kgco2e &&
                this.formData.employee_data.totals.total_kwh &&
                this.formData.employee_data.totals.total_litres &&
                this.formData.employee_data.work_from_home_percentage
            )
        }
    },
    beforeMount() {
        this.formData = {
            'employee_data': {
                'resources_used': this.data.employee_data.resources_used[0] === "" ? [] : this.data.employee_data.resources_used,
                'totals': {
                'total_gbp': this.data.employee_data.totals.total_gbp,
                'total_kgco2e': this.data.employee_data.totals.total_kgco2e,
                'total_kwh': this.data.employee_data.totals.total_kwh,
                'total_litres': this.data.employee_data.totals.total_litres,
                },
                'work_from_home_percentage': this.data.employee_data.work_from_home_percentage
            },
        }
        if(this.formData.employee_data.resources_used[0] &&
                this.formData.employee_data.totals.total_gbp &&
                this.formData.employee_data.totals.total_kgco2e &&
                this.formData.employee_data.totals.total_kwh &&
                this.formData.employee_data.totals.total_litres &&
                this.formData.employee_data.work_from_home_percentage) {
            this.$emit('finish', this.formData)
        }
    }
}
</script>