<template>
    <h1 style="color:#035c34;">Company Information</h1>
          <small style="margin-bottom:2em">Any fields marked with a * are required fields and must be filled out before continuing.</small><br /><br />
          <h4 style="margin-bottom:0.5em;color:#035c34;">What is your companies annual turnover?</h4>
          <v-text-field v-mask="['#', '##', '###', '#,###', '##,###', '###,###', '#,###,###', '##,###,###', '###,###,###', '#,###,###,###', '##,###,###,###', '###,###,###,###', '#,###,###,###,###']" masked="true" v-model="formData.annual_turnover_gbp" style="border-radius:1em;margin-bottom:1em;" variant="outlined" color="#7aaa3c" label="Annual Turnover (£)*" placeholder="Annual Turnover (£)*" required></v-text-field>
          <h4 style="margin-bottom:0.5em;color:#035c34;">How many full time employees does your company have?</h4>
          <v-text-field v-mask="['#', '##', '###', '#,###', '##,###', '###,###', '#,###,###', '##,###,###', '###,###,###', '#,###,###,###', '##,###,###,###', '###,###,###,###', '#,###,###,###,###']" masked="true"  v-model="formData.full_time_employees" style="border-radius:1em;margin-bottom:1em;" variant="outlined" color="#7aaa3c" label="Full Time Employees*" placeholder="Full Time Employees*" required></v-text-field>
          <h4 style="margin-bottom:0.5em;color:#035c34;">How many locations does your company have?</h4>
          <v-text-field v-mask="['#', '##', '###', '#,###', '##,###', '###,###', '#,###,###', '##,###,###', '###,###,###', '#,###,###,###', '##,###,###,###', '###,###,###,###', '#,###,###,###,###']" masked="true"  v-model="formData.location_quantity" style="border-radius:1em;margin-bottom:1em;" variant="outlined" color="#7aaa3c" label="Location Amount*" placeholder="Location Amount*" required></v-text-field>
          <h4 style="margin-bottom:0.5em;color:#035c34;">What industry is your company in?</h4>
          <v-select v-model="formData.industry_type" style="border-radius:1em;margin-bottom:1em;" variant="outlined" color="#7aaa3c" label="Industry Type*" placeholder="Industry Type*" :items="industries" required></v-select>
          <v-btn block size="x-large" color="#7aaa3c" :disabled="!isCompanyFormValid" @click="proceedApplication">Next</v-btn>
          <v-btn block size="x-large" @click="$emit('back')">Back</v-btn>
        
</template>
<script>
import axios from "axios"
export default {
    name: "ContactInformationComponent",
    data() {
        return {
            formData: {
                annual_turnover_gbp: null,
                full_time_employees: null,
                location_quantity: null,
                industry_type: null,
            },
            industries: [
                "Agriculture, Forestry, and Fishing",
                "Mining and Quarrying",
                "Utilities",
                "Construction",
                "Manufacturing",
                "Wholesale Trade",
                "Retail Trade",
                "Transportation and Warehousing",
                "Accommodation and Food Service",
                "Information and Communication",
                "Finance and Insurance",
                "Real Estate and Rental and Leasing",
                "Professional and Business Services",
                "Scientific and Technical Services",
                "Administrative and Support and Waste Management Services",
                "Educational Services",
                "Healthcare and Social Assistance",
                "Arts, Entertainment, and Recreation",
                "Other Services (except Public Administration)"
            ].sort()
        }
    },
    methods: {
        proceedApplication() {
            axios.post("https://carbon-application-flask.nw.r.appspot.com/store_data", Object.assign({current_page_no: this.pageno}, this.formData, {username: this.username, password: this.password})) .then((r) => {
                if(r.data.message === "Data successfully updated") {
                    this.$emit('finish', this.formData)
                } else {
                    alert("Something went wrong, please check your information.")
                }
            }) .catch((e) => {
                console.log(e)
            })
        }
    },
    computed: {
        isCompanyFormValid() {
      return this.formData.annual_turnover_gbp && this.formData.full_time_employees && this.formData.location_quantity && this.formData.industry_type
    }
    },
    props: {
        username: null,
        password: null,
        data: null,
        pageno: null,
    },
    beforeMount() {
        this.formData = {
            annual_turnover_gbp: this.data.annual_turnover_gbp,
            full_time_employees: this.data.full_time_employees,
            location_quantity: this.data.location_quantity,
            industry_type: this.data.industry_type
        }
        if(this.formData.annual_turnover_gbp && this.formData.full_time_employees && this.formData.location_quantity && this.formData.industry_type) {
            this.$emit('finish', this.formData)
        }
    }
}
</script>