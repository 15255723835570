<template>
    <h1 style="color:#035c34;">Energy Spend Information</h1>
          <h4 style="margin-bottom:0.5em;color:#035c34;">What was your total energy spend for the following energy types over the specified period?*</h4><br /><br />
            <v-row>
                <v-col style="height:3.5rem;line-height:3.5rem;">Electricity</v-col>
                <v-col><v-text-field v-mask="['#', '##', '###', '#,###', '##,###', '###,###', '#,###,###', '##,###,###', '###,###,###', '#,###,###,###', '##,###,###,###', '###,###,###,###', '#,###,###,###,###']" masked="true"  v-model="formData.energy_spend.electrcity.amount" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="Amount" placeholder="Amount" required></v-text-field></v-col>
                <v-col><v-select :items="electricity" v-model="formData.energy_spend.electrcity.unit" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="Unit" placeholder="Unit" required></v-select></v-col>
            </v-row>
            <v-row>
                <v-col style="height:3.5rem;line-height:3.5rem;">Natural Gas</v-col>
                <v-col><v-text-field v-mask="['#', '##', '###', '#,###', '##,###', '###,###', '#,###,###', '##,###,###', '###,###,###', '#,###,###,###', '##,###,###,###', '###,###,###,###', '#,###,###,###,###']" masked="true"  v-model="formData.energy_spend.natural_gas.amount" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="Amount" placeholder="Amount" required></v-text-field></v-col>
                <v-col><v-select :items="naturalgas" v-model="formData.energy_spend.natural_gas.unit" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="Unit" placeholder="Unit" required></v-select></v-col>
            </v-row>
            <v-row>
                <v-col style="height:3.5rem;line-height:3.5rem">Burning Oil/Kerosene</v-col>
                <v-col><v-text-field v-mask="['#', '##', '###', '#,###', '##,###', '###,###', '#,###,###', '##,###,###', '###,###,###', '#,###,###,###', '##,###,###,###', '###,###,###,###', '#,###,###,###,###']" masked="true"  v-model="formData.energy_spend.burning_oil_kerosene.amount" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="Amount" placeholder="Amount" required></v-text-field></v-col>
                <v-col><v-select :items="burningoil" v-model="formData.energy_spend.burning_oil_kerosene.unit" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="Unit" placeholder="Unit" required></v-select></v-col>
            </v-row>
            <v-row>
                <v-col style="height:3.5rem;line-height:3.5rem;">Diesel</v-col>
                <v-col><v-text-field v-mask="['#', '##', '###', '#,###', '##,###', '###,###', '#,###,###', '##,###,###', '###,###,###', '#,###,###,###', '##,###,###,###', '###,###,###,###', '#,###,###,###,###']" masked="true"  v-model="formData.energy_spend.diesel.amount" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="Amount" placeholder="Amount" required></v-text-field></v-col>
                <v-col><v-select :items="burningoil" v-model="formData.energy_spend.diesel.unit" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="Unit" placeholder="Unit" required></v-select></v-col>
            </v-row>
            <v-row>
                <v-col style="height:3.5rem;line-height:3.5rem;">Gas Oil</v-col>
                <v-col><v-text-field v-mask="['#', '##', '###', '#,###', '##,###', '###,###', '#,###,###', '##,###,###', '###,###,###', '#,###,###,###', '##,###,###,###', '###,###,###,###', '#,###,###,###,###']" masked="true"  v-model="formData.energy_spend.gas_oil.amount" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="Amount" placeholder="Amount" required></v-text-field></v-col>
                <v-col><v-select :items="burningoil" v-model="formData.energy_spend.gas_oil.unit" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="Unit" placeholder="Unit" required></v-select></v-col>
            </v-row>
            <v-row>
                <v-col style="height:3.5rem;line-height:3.5rem;">LPG</v-col>
                <v-col><v-text-field v-mask="['#', '##', '###', '#,###', '##,###', '###,###', '#,###,###', '##,###,###', '###,###,###', '#,###,###,###', '##,###,###,###', '###,###,###,###', '#,###,###,###,###']" masked="true"  v-model="formData.energy_spend.lpg.amount" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="Amount" placeholder="Amount" required></v-text-field></v-col>
                <v-col><v-select :items="burningoil" v-model="formData.energy_spend.lpg.unit" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="Unit" placeholder="Unit" required></v-select></v-col>
            </v-row>
            <v-row>
                <v-col style="height:3.5rem;line-height:3.5rem;">Water Consumed</v-col>
                <v-col><v-text-field v-mask="['#', '##', '###', '#,###', '##,###', '###,###', '#,###,###', '##,###,###', '###,###,###', '#,###,###,###', '##,###,###,###', '###,###,###,###', '#,###,###,###,###']" masked="true"  v-model="formData.energy_spend.water_consumed.amount" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="Amount" placeholder="Amount" required></v-text-field></v-col>
                <v-col><v-select :items="water" v-model="formData.energy_spend.water_consumed.unit" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="Unit" placeholder="Unit" required></v-select></v-col>
            </v-row>
            <br />
            <h4 style="margin-bottom:0.5em;color:#035c34;">What was your total energy spend for the following energy units over the specified period? <i>(These fields are optional, if you leave them blank we will calculate these for you.)</i></h4><br /><br />
          <v-row>
            <v-col style="height:3.5rem;line-height:3.5rem;">kWh</v-col>
            <v-col><v-text-field v-model="formData.energy_spend.total_kwh" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="Amount" placeholder="Amount" required></v-text-field></v-col>
            <v-col style="height:3.5rem;line-height:3.5rem;">Total £</v-col>
            <v-col><v-text-field v-model="formData.energy_spend.total_gbp" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="Amount" placeholder="Amount" required></v-text-field></v-col>
          </v-row>
          <v-row>
            <v-col style="height:3.5rem;line-height:3.5rem;">Litres</v-col>
            <v-col><v-text-field v-model="formData.energy_spend.total_litres" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="Amount" placeholder="Amount" required></v-text-field></v-col>
            <v-col style="height:3.5rem;line-height:3.5rem;">kgCO₂e</v-col>
            <v-col><v-text-field v-model="formData.energy_spend.total_kgco2e" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="Amount" placeholder="Amount" required></v-text-field></v-col>
        </v-row>
          <v-btn block size="x-large" color="#7aaa3c" :disabled="!isEnergySpendValid" @click="proceedApplication">Next</v-btn>
          <v-btn block size="x-large" @click="$emit('back')">Back</v-btn>
</template>
<script>
import axios from "axios"
export default {
    name: "EnergySpendInformation",
    data() {
        return {
            formData: {
                energy_spend: {
                burning_oil_kerosene: {amount: '', unit: ''},
                diesel: {amount: '', unit: ''},
                electrcity: {amount: '', unit: ''},
                gas_oil: {amount: '', unit: ''},
                lpg: {amount: '', unit: ''},
                natural_gas: {amount: '', unit: ''},
                water_consumed: {amount: '', unit: 'L'},
                total_kwh: '',
                total_gbp: '',
                total_litres: '',
                total_kgco2e: '',
                },
            },
            electricity: ['kWh'],
            naturalgas: ['m³', 'thm'],
            burningoil: ['L', 'gal'],
            water: ['m³', 'gal']
        }
    },
    props: {
        username: null,
        password: null,
        data: null,
        pageno: null,
    },
    methods: {
        proceedApplication() {
            axios.post("https://carbon-application-flask.nw.r.appspot.com/store_data", Object.assign({current_page_no: this.pageno}, this.formData, {username: this.username, password: this.password})) .then((r) => {
                if(r.data.message === "Data successfully updated") {
                    this.$emit('finish', this.formData)
                } else {
                    alert("Something went wrong, please check your information.")
                }
            }) .catch((e) => {
                console.log(e)
            })
        }
    },
    computed: {
        isEnergySpendValid() {
            return (
                this.formData.energy_spend.burning_oil_kerosene.amount &&
                this.formData.energy_spend.burning_oil_kerosene.unit &&
                this.formData.energy_spend.diesel.amount &&
                this.formData.energy_spend.diesel.unit &&
                this.formData.energy_spend.electrcity.amount &&
                this.formData.energy_spend.electrcity.unit &&
                this.formData.energy_spend.gas_oil.amount &&
                this.formData.energy_spend.gas_oil.unit &&
                this.formData.energy_spend.lpg.amount &&
                this.formData.energy_spend.lpg.unit &&
                this.formData.energy_spend.natural_gas.amount &&
                this.formData.energy_spend.natural_gas.unit &&
                this.formData.energy_spend.water_consumed.amount &&
                this.formData.energy_spend.water_consumed.unit
            )
        }
    },
    beforeMount() {
        this.formData = {
            energy_spend: {
                burning_oil_kerosene: {amount: this.data.energy_spend.burning_oil_kerosene.amount, unit: this.data.energy_spend.burning_oil_kerosene.unit},
                diesel: {amount: this.data.energy_spend.diesel.amount, unit: this.data.energy_spend.diesel.unit},
                electrcity: {amount: this.data.energy_spend.electrcity.amount, unit: this.data.energy_spend.electrcity.unit},
                gas_oil: {amount: this.data.energy_spend.gas_oil.amount, unit: this.data.energy_spend.gas_oil.unit},
                lpg: {amount: this.data.energy_spend.lpg.amount, unit: this.data.energy_spend.lpg.unit},
                natural_gas: {amount: this.data.energy_spend.natural_gas.amount, unit: this.data.energy_spend.natural_gas.unit},
                water_consumed: {amount: this.data.energy_spend.water_consumed.amount, unit: this.data.energy_spend.water_consumed.unit},
                total_kwh: this.data.energy_spend.total_kwh,
                total_gbp: this.data.energy_spend.total_gbp,
                total_litres: this.data.energy_spend.total_litres,
                total_kgco2e: this.data.energy_spend.total_kgco2e,
                },
        }
        if(this.formData.energy_spend.burning_oil_kerosene.amount &&
                this.formData.energy_spend.burning_oil_kerosene.unit &&
                this.formData.energy_spend.diesel.amount &&
                this.formData.energy_spend.diesel.unit &&
                this.formData.energy_spend.electrcity.amount &&
                this.formData.energy_spend.electrcity.unit &&
                this.formData.energy_spend.gas_oil.amount &&
                this.formData.energy_spend.gas_oil.unit &&
                this.formData.energy_spend.lpg.amount &&
                this.formData.energy_spend.lpg.unit &&
                this.formData.energy_spend.natural_gas.amount &&
                this.formData.energy_spend.natural_gas.unit &&
                this.formData.energy_spend.water_consumed.amount &&
                this.formData.energy_spend.water_consumed.unit) {
            this.$emit('finish', this.formData)
        }
    }
}
</script>