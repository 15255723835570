<template>
    <h1 style="color:#035c34;">Contact Information</h1>
    <small style="margin-bottom:2em">Any fields marked with a * are required fields and must be filled out before continuing.</small><br /><br />
    <v-text-field v-model="formData.first_name" style="border-radius:1em;margin-bottom:1em;" variant="outlined" color="#7aaa3c" label="First Name*" placeholder="First Name*" required></v-text-field>
    <v-text-field v-model="formData.last_name" style="border-radius:1em;margin-bottom:1em;" variant="outlined" color="#7aaa3c" label="Surname*" placeholder="Surname*" required></v-text-field>
    <v-text-field v-model="formData.phone_number" style="border-radius:1em;margin-bottom:1em;" variant="outlined" color="#7aaa3c" label="Phone Number*" placeholder="Phone Number*" required></v-text-field>
    <v-text-field v-model="formData.address" style="border-radius:1em;margin-bottom:1em;" variant="outlined" color="#7aaa3c" label="Address*" placeholder="Address*" required></v-text-field>
    <v-text-field v-model="formData.company_name" style="border-radius:1em;margin-bottom:1em;" variant="outlined" color="#7aaa3c" label="Company Name*" placeholder="Company Name*" required></v-text-field>
    <v-checkbox v-model="privacy" color="#035c34" label="By ticking this box you are agreeing to our Terms of Service and that you have read our Privacy Policy.">
        <template #label>
            By ticking this box you are agreeing to our &nbsp; <a style="text-decoration: underline;color:#78A892" @click="tosdiag = true">Terms of Service</a> &nbsp; and that you have read our &nbsp; <a style="text-decoration: underline;color:#78A892" @click="ppdiag = true">Privacy Policy.</a>
        </template>
    </v-checkbox>
    <v-btn block size="x-large" color="#7aaa3c" :disabled="!isUserFormValid" @click="proceedApplication()">Next</v-btn>
    <v-dialog style="width:50vw;height:60vh;" v-model="tosdiag">
            <v-card style="width:50vw;height:60vh;" class="pa-7">
                <v-card-title class="mb-5"><h1>Terms of Service</h1></v-card-title>
                <v-card-text style="overflow:scroll" class="w-100 h-50">
                    <p>These Terms of Service ("Terms") govern your access to and use of the CarbonInsight application (the "App") developed by a collaboration of WildTrackPro Ltd. & Consultiv Utilities Limited. ("we," "us," or "our"). By accessing or using the App, you agree to be bound by these Terms. If you do not agree to all of these Terms, you may not access or use the App.</p>

                    <h2>Definitions</h2>
                    <p>
                        * "Account" means your account created for use of the App. <br>
                        * "Content" means any information, data, text, graphics, or other materials uploaded, submitted, or displayed in the App. <br>
                        * "User" means you, the individual or entity accessing or using the App.
                    </p>

                    <h2>User Accounts</h2>
                    <p>
                        * You may be required to create an account to access certain features of the App. <br>
                        * You are responsible for maintaining the confidentiality of your account credentials and are fully responsible for all activities that occur under your account. <br>
                        * You agree to notify us immediately of any unauthorized use of your account or any other security breach.
                    </p>

                    <h2>Use of the App</h2>
                    <p>
                        * You will not use the App for any illegal or unauthorized purpose. <br>
                        * You will not violate or attempt to violate the security of the App. <br>
                        * You will not upload, transmit, or store any Content that is infringing, libelous, defamatory, obscene, or otherwise objectionable. <br>
                        * You will not interfere with or disrupt the use of the App by others.
                    </p>

                    <h2>User Content</h2>
                    <p>
                        * You retain all ownership rights to your Content. However, by uploading or submitting Content to the App, you grant us a non-exclusive, royalty-free, worldwide license to use, reproduce, modify, publish, and distribute your Content in connection with the App. <br>
                        * You are responsible for the accuracy and completeness of your Content.
                    </p>

                    <h2>Third-Party Services</h2>
                    <p>
                        * The App may contain links to or integrate with third-party services. We are not responsible for the content or practices of any third-party services. <br>
                        * Your use of third-party services is subject to the terms and conditions of those services.
                    </p>

                    <h2>Intellectual Property</h2>
                    <p>
                        * The App and all intellectual property rights therein are owned by us or our licensors. <br>
                        * You may not use any of our trademarks, logos, or service marks without our prior written consent.
                    </p>

                    <h2>Disclaimers</h2>
                    <p>
                        * THE APP IS PROVIDED "AS IS" AND WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED. WE DISCLAIM ALL WARRANTIES, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. <br>
                        * WE DO NOT WARRANT THAT THE APP WILL BE UNINTERRUPTED OR ERROR-FREE. <br>
                        * WE DO NOT WARRANT THAT THE APP WILL BE SECURE OR VIRUS-FREE.
                    </p>

                    <h2>Limitation of Liability</h2>
                    <p>
                        * WE WILL NOT BE LIABLE FOR ANY DAMAGES ARISING OUT OF OR RELATED TO YOUR USE OF THE APP, INCLUDING, BUT NOT LIMITED TO, DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, OR SPECIAL DAMAGES.
                    </p>

                    <h2>Term and Termination</h2>
                    <p>
                        * These Terms will remain in effect until terminated by you or us. <br>
                        * We may terminate these Terms or your access to the App for any reason, at any time, without notice.
                    </p>

                    <h2>Governing Law</h2>
                    <p>
                        * These Terms will be governed by and construed in accordance with the laws of England and Wales.
                    </p>

                    <h2>Dispute Resolution</h2>
                    <p>
                        * Any dispute arising out of or relating to these Terms will be resolved by the courts of England and Wales.
                    </p>
                    <h2>Entire Agreement</h2>
                    <p> These terms constitute the entire agreement between you and us regarding your use of the App.</p>
                    <h2>Severability</h2>
                    <p>
                        * If any provision of these Terms is held to be invalid or unenforceable, such provision will be struck and the remaining provisions will remain in effect.
                    </p>

                    <h2>Waiver</h2>
                    <p>
                        * Our failure to enforce any provision of these Terms will not be construed as a waiver of such provision.
                    </p>

                    <h2>Updates to the Terms</h2>
                    <p>
                        * We may update these Terms from time to time. We will notify you of any changes by posting the new Terms on the App. <br>
                        * Your continued use of the App after the posting of revised Terms means that you accept and agree to the changes.
                    </p>

                    <h2>Contact Us</h2>
                    <p>
                        If you have any questions about these Terms, please contact us at support@wildtrackpro.com
                    </p>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog style="width:50vw;height:60vh;" v-model="ppdiag">
            <v-card style="width:50vw;height:60vh;" class="pa-7">
                <v-card-title class="mb-5"><h1>Privacy Policy</h1></v-card-title>
                <v-card-text style="overflow:scroll" class="w-100 h-50">
                    <h1>1. Introduction</h1>

                    <p>This Privacy Policy explains how CarbonInsight, a service offered in collaboration by WildTrackPro Ltd. & Consultiv Utilities Limited., collects, uses, and discloses your personal data. It applies to all users of the CarbonInsight application.</p>

                    <h1>2. Who We Are</h1>

                    WildTrackPro Ltd. is the data controller responsible for your personal data collected through CarbonInsight. You can contact us at support@wildtrackpro.com with any questions about this Privacy Policy.

                    <h1>3. What Personal Data We Collect</h1>

                    We may collect the following personal data about your business through CarbonInsight:

                    Company name and contact details (e.g., address, phone number, email)
                    Business activity and industry
                    Data related to your carbon footprint (e.g., energy usage, waste generation)

                    <h1>4. How We Use Your Personal Data</h1>

                    We use your personal data to:

                    Provide and operate CarbonInsight services<br />
                    Track your carbon footprint and offer insights for improvement<br />
                    Communicate with you about your account and CarbonInsight services<br />
                    Improve the CarbonInsight app and services<br />
                    Send marketing materials related to similar or associated products or services by text or email<br />
                    If you wish to opt out of marketing texts and emails please notify us using the email address provided below.<br />
                    support@wildtrackpro.com

                    <h1>5. Lawful Basis for Processing</h1>

                    We process your personal data based on the following lawful basis:

                    Contractual necessity: To provide you with the CarbonInsight services you have requested.
                    Legitimate interest: To improve the CarbonInsight app and services and provide you with a better user experience.
                    <h1>6. Data Retention</h1>

                    We will retain your personal data for as long as necessary to fulfill the purposes outlined in Section 4 or until you request deletion (see Section 8).

                    <h1>7. Data Sharing</h1>

                    We will not share your personal data with any third party without your consent, except in the following limited circumstances:

                    To comply with a legal obligation
                    To protect the rights, property, or safety of ourselves or others
                    <h1>8. Your Rights</h1>

                    Under the UK GDPR, you have certain rights regarding your personal data. These rights include:

                    The right to access your personal data
                    The right to rectification of inaccurate personal data
                    The right to erasure of your personal data
                    The right to restrict the processing of your personal data
                    The right to data portability
                    You can exercise these rights by contacting us at support@wildtrackpro.com.

                    <h1>9. Security</h1>

                    We take reasonable steps to protect your personal data from unauthorized access, disclosure, alteration, or destruction.

                    <h1>10. Changes to this Privacy Policy</h1>

                    We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on the CarbonInsight app.

                    <h1>11. Contact Us</h1>

                    If you have any questions about this Privacy Policy, please contact us at support@wildtrackpro.com.
                </v-card-text>
            </v-card>
        </v-dialog>
</template>
<script>
import axios from "axios"
export default {
    name: "ContactInformationComponent",
    data() {
        return {
            tosdiag: false,
            ppdiag: false,
            formData: {
                first_name: null,
                last_name: null,
                phone_number: null,
                address: null,
                company_name: null,
                
            },
            privacy: false,
        }
    },
    props: {
        username: null,
        password: null,
        data: null,
        pageno: null,
    },
    methods: {
        proceedApplication() {
            axios.post("https://carbon-application-flask.nw.r.appspot.com/store_data", Object.assign({current_page_no: this.pageno}, this.formData, {username: this.username, password: this.password})) .then((r) => {
                if(r.data.message === "Data successfully updated") {
                    this.$emit('finish', this.formData)
                } else {
                    alert("Something went wrong, please check your information.")
                }
            }) .catch((e) => {
                console.log(e)
            })
        }
    },
    computed: {
        isUserFormValid() {
            return this.formData.company_name && this.formData.first_name && this.formData.last_name && this.formData.phone_number && this.formData.address && this.privacy
        },
    },
    beforeMount() {
        this.formData = {
            first_name: this.data.first_name,
            last_name: this.data.last_name,
            phone_number: this.data.phone_number,
            address: this.data.address,
            company_name: this.data.company_name
        }
        if(this.data.company_name && this.data.first_name && this.data.last_name && this.data.phone_number && this.data.address) {
            this.$emit('finish', this.formData)
        }
    }
}
</script>