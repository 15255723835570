<template>
  <v-row>
    <v-col style="padding:2em 5em 5em 5em;">
      <div style="width:100%">
        <!-- Navigation bar -->
        <div style="height:7.5vh;padding:0.5em;border-top-right-radius: 1em;border-top-left-radius: 1em;" class="header">
          <v-row>
            <v-col style="height:7.5vh;width:50%;margin-top:0.5em;margin-left:0.5em"> <span class="helper"><img :src="Logo" style="height:7.5vh; width:auto;float:center;" /></span></v-col>
          </v-row>
        </div>
        <!-- Content -->
        <!-- TODO: Create a handler for if data already exists. A login screen could be effective if we can get that system up, else unique device ID or something like that. Unique device ID could be an issue if using multiple machines. -->
        <!-- /TIP: To validate optional parts of the form, wrap the components in <span> or another invisible tag and use v-conditionals -->
        <v-window v-model="page_counter" style="width:100%;border-bottom-left-radius:1em;border-bottom-right-radius:1em;padding:3em;" v-if="!userFormCompleted">
          <v-window-item :key="-1">
            <LoginComponent  @finish="storeLogin" />
          </v-window-item>
          <v-window-item :key="0">
            <ContactInformation :username="username" :password="password" :data="allData" pageno="0" @finish="handleReturn" />
          </v-window-item>
          <v-window-item :key="1">
            <CompanyInformation :username="username" :password="password" :data="allData" pageno="1" @finish="handleReturn" @back="goBack" />
          </v-window-item>
          <v-window-item :key="2">
            <ReportInformation :username="username" :password="password" :data="allData" pageno="2" @finish="handleReturn" @back="goBack" />
          </v-window-item>
          <v-window-item :key="3">
          <FloorAreaInformation :username="username" :password="password" :data="allData" pageno="3" @finish="handleReturn" @back="goBack" />
          </v-window-item>
          <v-window-item :key="4">
          <EnergySpendInformation :username="username" :password="password" :data="allData" pageno="4" @finish="handleReturn" @back="goBack" />
          </v-window-item>
          <v-window-item :key="5">
          <PositionInformation :username="username" :password="password" :data="allData" pageno="5" @finish="handleReturn" @back="goBack" />
          </v-window-item>
          <v-window-item :key="6">
            <!-- Checks to see if "employee" was selected during the position questionnaire -->
            <span v-if="allData.company_positions.includes('Employee') || master_data_array.company_positions.includes('Employee')">
              <EmployeeInformation :username="username" :password="password" :data="allData" pageno="6" @finish="handleReturn" @back="goBack" />
            </span>
            <span v-else>
              <small style="margin-bottom:2em">You did not select "employee" as a position. Go back if this is incorrect, else click next.</small><br /><br />
              <v-btn block size="x-large" color="#7aaa3c" @click="this.page_counter = this.page_counter + 1">Next</v-btn>
              <v-btn block size="x-large" color="#7aaa3c" @click="this.page_counter = this.page_counter - 1">Back</v-btn>
            </span>
          </v-window-item>
          <v-window-item :key="7">
            <!-- Checks to see if "management representative" was selected during the position questionnaire -->
            <span v-if="allData.company_positions.includes('Management Representative') || master_data_array.company_positions.includes('Management Representative')">
              <ManagementInformation :username="username" :password="password" :data="allData" pageno="7" @finish="handleReturn" @back="goBack" />
            </span>
            <span v-else>
              <small style="margin-bottom:2em">You did not select "management represenatitve" as a position. Go back if this is incorrect, else click next.</small><br /><br />
              <v-btn block size="x-large" color="#7aaa3c" @click="this.page_counter = this.page_counter + 1">Next</v-btn>
              <v-btn block size="x-large" color="#7aaa3c" @click="this.page_counter = this.page_counter - 1">Back</v-btn>
            </span>
          </v-window-item>
          <v-window-item :key="8">
            <VehicleInformation :username="username" :password="password" :data="allData" pageno="8" @finish="handleReturn" @back="goBack" />
          </v-window-item>
          <v-window-item :key="9">
            <FuelTypeInformation :username="username" :password="password" :data="allData" pageno="9" @finish="handleReturn" @back="goBack" />
          </v-window-item>
          <v-window-item :key="10">
            <TotalInformation :username="username" :password="password" :data="allData" pageno="10" @finish="handleReturn" @back="goBack" />
          </v-window-item>
          <v-window-item :key="11">
            <BusinessTravelInformation :username="username" :password="password" :data="allData" pageno="11" @finish="handleReturn" @back="goBack" />
            <!-- TODO: Create handler to send the master_data_array object to the back end. This can deviate from the handleReturn function to one that increments the window value depending on the result of the upload. -->
          </v-window-item>
          <v-window-item :key="12">
            <MainScreen :username="username" :password="password" :data="allData" pageno="12" @finish="handleReturn" @back="goBack" />
          </v-window-item>
        </v-window>
      </div>
    </v-col>
  </v-row>
</template>
<script>
// Files
import Logo from "../assets/CarbonInsight_CUgroup.png"

// Components
import ContactInformation from "../components/ContactInformation.vue"
import CompanyInformation from "../components/CompanyInformation.vue"
import ReportInformation from "../components/ReportInformation.vue"
import FloorAreaInformation from "../components/FloorAreaInformation.vue"
import EnergySpendInformation from "../components/EnergySpendInformation.vue"
import PositionInformation from "../components/PositionInformation.vue"
import EmployeeInformation from "../components/EmployeeInformation.vue"
import ManagementInformation from "../components/ManagementInformation.vue"
import VehicleInformation from "../components/VehicleInformation.vue"
import FuelTypeInformation from "../components/FuelTypeInformation.vue"
import TotalInformation from "../components/TotalInformation.vue"
import BusinessTravelInformation from "../components/BusinessTravelInformation.vue"
import MainScreen from "../components/MainScreen.vue"
import LoginComponent from "../components/SignInComponent.vue"
export default {
  name: "QuestionnaireComponent",
  data: () => ({
    page_counter: 0,
    master_data_array: {
  'address': '',
  'annual_turonover_gbp': '',
  'business_travel': {
    'bus_coach_journeys': {'cost': '', 'number': ''},
    'ferry_journeys': {'cost': '', 'number': ''},
    'flights_taken': {'cost': '', 'number': ''},
    'hotel_nights': {'cost': '', 'number': ''},
    'reimbursed_milage': {'cost': '', 'number': ''},
    'taxi_journeys': {'cost': '', 'number': ''},
    'train_journeys': {'cost': '', 'number': ''},
    'total_cost': '',
    'total_kgco2e': '',
    'total_kwh': ''
  },
  'business_travel_types': ['', '', '', ''],
  'company_name': '',
  'company_positions': ['', ''],
  'company_vehicles': {
    '4x4_suv': {'fuel_type': '', 'number': ''},
    'aeroplane': {'fuel_type': '', 'number': ''},
    'bus_coach': {'fuel_type': '', 'number': ''},
    'company_car': {'fuel_type': '', 'number': ''},
    'hgv': {'fuel_type': '', 'number': ''},
    'motorbike': {'fuel_type': '', 'number': ''},
    'mpv_minibus': {'fuel_type': '', 'number': ''},
    'ship': {'fuel_type': '', 'number': ''},
    'train_engine': {'fuel_type': '', 'number': ''},
    'van_lgv': {'fuel_type': '', 'number': ''},
    'total': ''
  },
  'email': '',
  'employee_data': {
    'resources_used': ['', '', '', '', ''],
    'totals': {
      'total_gbp': '',
      'total_kgco2e': '',
      'total_kwh': '',
      'total_litres': '',
    },
    'work_from_home_percentage': ''
  },
  'energy_spend': {
    'burning_oil_kerosene': {'amount': '', 'unit': ''},
    'diesel': {'amount': '', 'unit': ''},
    'electrcity': {'amount': '', 'unit': ''},
    'gas_oil': {'amount': '', 'unit': ''},
    'lpg': {'amount': '', 'unit': ''},
    'natural_gas': {'amount': '', 'unit': ''},
    'water_consumed': {'amount': '', 'unit': 'L'},
    'total_kwh': '',
    'total_gbp': '',
    'total_litres': '',
    'total_kgco2e': '',
  },
  'first_name': '',
  'fleet_field_totals': {
    'cost': '',
    'kgco2e': '',
    'kwh': '',
    'litres': '',
    'miles': '',
  },
  'floor_area': {
    'accomodation': '',
    'administrativeoffice': '',
    'labprocessing': '',
    'lecturehalltheatre': '',
    'manufacturing': '',
    'retail': '',
    'foodservery': '',
    'warehousing': '',
    'workshop': '',
    'total': ''
  },
  'full_time_employees': '',
  'industry_type': '',
  'last_name': '',
  'litres_purchased_fuel': {
    'aviation_fuel': '',
    'diesel': '',
    'electricity': '',
    'hybrid': '',
    'mixed': '',
    'petrol': '',
    'total': '',
  },
  'location_quantity': '',
  'management_representative_data': {
    'employee_wfh': {
      '1_day_week': '',
      '2_day_week': '',
      '3_day_week': '',
      '4_day_week': '',
      '5_day_week': '',
    },
    'totals': {
      'total_gbp': '',
      'total_kgco2e': '',
      'total_kwh': '',
      'total_litres': '',
    },
    'work_from_home_percentage': ''
  },
  'phone_number': '',
  'questionnaire_period': ['', ''],
  'reimbursed_miles': {
    '4x4_suv': '',
    'company_car': '',
    'motorbike': '',
    'mpv_minibus': '',
    'van': '',
  }
}, // Full carbon data array identical to the flutter structure
    company_name: null,
    company_turnover: null,
    company_fte: null,
    company_industry: null,
    company_locations: null,
    companyFormCompleted: false,
    timePeriodFormCompleted: false,
    ch_to: null,
    ch_from: null,
    ch_1: false,
    ch_2: false,
    ch_3: false,
    ch_4: false,
    username: null,
    password: null,
    allData: null,
  }),
  // Mounts the logo file so the build can reference it as absolute
  setup: () => {
    return { Logo };
  },
  // Registered components so they are loaded into the DOM when this component is loading. Also ensures full render instead of lazy loading.
  components: {
    ContactInformation,
    CompanyInformation,
    ReportInformation,
    FloorAreaInformation,
    EnergySpendInformation,
    PositionInformation,
    EmployeeInformation,
    ManagementInformation,
    VehicleInformation,
    FuelTypeInformation,
    TotalInformation,
    BusinessTravelInformation,
    MainScreen,
    LoginComponent
  },
  methods: {
    // This takes the returned array from the component and integrates it into the overall data array.
    // The event variable is the inside of the formData JSON within each individual component.
    handleReturn(event) {
      Object.assign(this.master_data_array, event)
      // Increments window position
      this.page_counter = this.page_counter + 1
    },
    goBack() {
      // Decrements window position (if that's the right word)
      this.page_counter = this.page_counter - 1
    },
    storeLogin(event) {
      this.username = event[0].username
      this.password = event[0].password
      this.allData = event[1]
      if(this.allData.isComplete === "true") {
        this.page_counter = this.page_counter + 15
      }
      this.page_counter = parseInt(this.allData.current_page_no) + 1
    }
  },

}

</script>