<template>
    <h1 style="color:#035c34;">Company Vehicle Information</h1>
          <small style="margin-bottom:2em">For each of the following vehicles please enter how many of each your company owned during the 12 month period as well as the main fuel type.*</small><br /><br />
            <v-row>
                <v-col style="height:3.5rem;line-height:3.5rem;">Motorbike</v-col>
                <v-col><v-text-field v-model="formData.company_vehicles.motorbike.number" v-mask="['#', '##', '###', '#,###', '##,###', '###,###', '#,###,###', '##,###,###', '###,###,###', '#,###,###,###', '##,###,###,###', '###,###,###,###', '#,###,###,###,###']" masked="true"  style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="No." placeholder="No." required></v-text-field></v-col>
                <v-col><v-select :items="motorbike" v-model="formData.company_vehicles.motorbike.fuel_type" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="Fuel Type" placeholder="Fuel Type" required></v-select></v-col>
            </v-row>
            <v-row>
                <v-col style="height:3.5rem;line-height:3.5rem;">Company Car</v-col>
                <v-col><v-text-field v-model="formData.company_vehicles.company_car.number" v-mask="['#', '##', '###', '#,###', '##,###', '###,###', '#,###,###', '##,###,###', '###,###,###', '#,###,###,###', '##,###,###,###', '###,###,###,###', '#,###,###,###,###']" masked="true"  style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="No." placeholder="No." required></v-text-field></v-col>
                <v-col><v-select :items="companycar"  v-model="formData.company_vehicles.company_car.fuel_type" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="Fuel Type" placeholder="Fuel Type" required></v-select></v-col>
            </v-row>
            <v-row>
                <v-col style="height:3.5rem;line-height:3.5rem;">4X4 / SUV</v-col>
                <v-col><v-text-field v-model="formData.company_vehicles['4x4_suv'].number" v-mask="['#', '##', '###', '#,###', '##,###', '###,###', '#,###,###', '##,###,###', '###,###,###', '#,###,###,###', '##,###,###,###', '###,###,###,###', '#,###,###,###,###']" masked="true"  style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="No." placeholder="No." required></v-text-field></v-col>
                <v-col><v-select :items="suv"  v-model="formData.company_vehicles['4x4_suv'].fuel_type" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="Fuel Type" placeholder="Fuel Type" required></v-select></v-col>
            </v-row>
            <v-row>
                <v-col style="height:3.5rem;line-height:3.5rem;">MPV / Mini Bus</v-col>
                <v-col><v-text-field v-model="formData.company_vehicles.mpv_minibus.number" v-mask="['#', '##', '###', '#,###', '##,###', '###,###', '#,###,###', '##,###,###', '###,###,###', '#,###,###,###', '##,###,###,###', '###,###,###,###', '#,###,###,###,###']" masked="true"  style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="No." placeholder="No." required></v-text-field></v-col>
                <v-col><v-select :items="mpv"  v-model="formData.company_vehicles.mpv_minibus.fuel_type" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="Fuel Type" placeholder="Fuel Type" required></v-select></v-col>
            </v-row>
            <v-row>
                <v-col style="height:3.5rem;line-height:3.5rem;">Van (LGV)</v-col>
                <v-col><v-text-field v-model="formData.company_vehicles.van_lgv.number" v-mask="['#', '##', '###', '#,###', '##,###', '###,###', '#,###,###', '##,###,###', '###,###,###', '#,###,###,###', '##,###,###,###', '###,###,###,###', '#,###,###,###,###']" masked="true"  style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="No." placeholder="No." required></v-text-field></v-col>
                <v-col><v-select :items="lgv" v-model="formData.company_vehicles.van_lgv.fuel_type" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="Fuel Type" placeholder="Fuel Type" required></v-select></v-col>
            </v-row>
            <v-row>
                <v-col style="height:3.5rem;line-height:3.5rem;">HGV</v-col>
                <v-col><v-text-field v-model="formData.company_vehicles.hgv.number" v-mask="['#', '##', '###', '#,###', '##,###', '###,###', '#,###,###', '##,###,###', '###,###,###', '#,###,###,###', '##,###,###,###', '###,###,###,###', '#,###,###,###,###']" masked="true"  style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="No." placeholder="No." required></v-text-field></v-col>
                <v-col><v-select :items="hgv"  v-model="formData.company_vehicles.hgv.fuel_type" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="Fuel Type" placeholder="Fuel Type" required></v-select></v-col>
            </v-row>
            <v-row>
                <v-col style="height:3.5rem;line-height:3.5rem;">Bus/Coach</v-col>
                <v-col><v-text-field v-model="formData.company_vehicles.bus_coach.number" v-mask="['#', '##', '###', '#,###', '##,###', '###,###', '#,###,###', '##,###,###', '###,###,###', '#,###,###,###', '##,###,###,###', '###,###,###,###', '#,###,###,###,###']" masked="true"  style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="No." placeholder="No." required></v-text-field></v-col>
                <v-col><v-select :items="bus"  v-model="formData.company_vehicles.bus_coach.fuel_type" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="Fuel Type" placeholder="Fuel Type" required></v-select></v-col>
            </v-row>
            <v-row>
                <v-col style="height:3.5rem;line-height:3.5rem;">Train/Engine</v-col>
                <v-col><v-text-field v-model="formData.company_vehicles.train_engine.number" v-mask="['#', '##', '###', '#,###', '##,###', '###,###', '#,###,###', '##,###,###', '###,###,###', '#,###,###,###', '##,###,###,###', '###,###,###,###', '#,###,###,###,###']" masked="true"  style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="No." placeholder="No." required></v-text-field></v-col>
                <v-col><v-select :items="train"  v-model="formData.company_vehicles.train_engine.fuel_type" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="Fuel Type" placeholder="Fuel Type" required></v-select></v-col>
            </v-row>
            <v-row>
                <v-col style="height:3.5rem;line-height:3.5rem;">Aeroplane</v-col>
                <v-col><v-text-field v-model="formData.company_vehicles.aeroplane.number" v-mask="['#', '##', '###', '#,###', '##,###', '###,###', '#,###,###', '##,###,###', '###,###,###', '#,###,###,###', '##,###,###,###', '###,###,###,###', '#,###,###,###,###']" masked="true"  style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="No." placeholder="No." required></v-text-field></v-col>
                <v-col><v-select :items="plane"  v-model="formData.company_vehicles.aeroplane.fuel_type" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="Fuel Type" placeholder="Fuel Type" required></v-select></v-col>
            </v-row>
            <v-row>
                <v-col style="height:3.5rem;line-height:3.5rem;">Ship</v-col>
                <v-col><v-text-field v-model="formData.company_vehicles.ship.number" v-mask="['#', '##', '###', '#,###', '##,###', '###,###', '#,###,###', '##,###,###', '###,###,###', '#,###,###,###', '##,###,###,###', '###,###,###,###', '#,###,###,###,###']" masked="true"  style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="No." placeholder="No." required></v-text-field></v-col>
                <v-col><v-select :items="ship" v-model="formData.company_vehicles.ship.fuel_type" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="Fuel Type" placeholder="Fuel Type" required></v-select></v-col>
            </v-row>
            <v-row>
                <v-col style="height:3.5rem;line-height:3.5rem;font-weight:bold">Total</v-col>
                <v-col><v-text-field v-model="totalNumber" disabled style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="No." placeholder="No." required></v-text-field></v-col>
            </v-row>
            <br />
          <v-btn block size="x-large" color="#7aaa3c" :disabled="!isEnergySpendValid" @click="proceedApplication">Next</v-btn>
          <v-btn block size="x-large" @click="$emit('back')">Back</v-btn>
</template>
<script>
import axios from "axios"
export default {
    name: "EnergySpendInformation",
    data() {
        return {
            formData: {
                'company_vehicles': {
                    '4x4_suv': {'fuel_type': '', 'number': ''},
                    'aeroplane': {'fuel_type': '', 'number': ''},
                    'bus_coach': {'fuel_type': '', 'number': ''},
                    'company_car': {'fuel_type': '', 'number': ''},
                    'hgv': {'fuel_type': '', 'number': ''},
                    'motorbike': {'fuel_type': '', 'number': ''},
                    'mpv_minibus': {'fuel_type': '', 'number': ''},
                    'ship': {'fuel_type': '', 'number': ''},
                    'train_engine': {'fuel_type': '', 'number': ''},
                    'van_lgv': {'fuel_type': '', 'number': ''},
                    'total': ''
                },
            },
        motorbike: ["Gasoline", "Electric", "N/A"],
        companycar: ["Gasoline", "Diesel", "Electric", "Hybrid", "N/A"],
        suv: ["Gasoline", "Diesel", "Hybrid", "N/A"],
        mpv: ["Gasoline", "Diesel", "LPG", "N/A"], // LPG - Liquefied Petroleum Gas
        lgv: ["Gasoline", "Diesel", "Electric", "N/A"],
        hgv: ["Diesel", "N/A"], // Primarily use Diesel
        bus: ["Diesel", "CNG (Compressed Natural Gas)", "N/A"], // Include CNG as an option
        train: ["Diesel", "Electric", "N/A"],
        plane: ["Jet Fuel", "N/A"], // Primarily use Jet Fuel
        ship: ["Heavy Fuel Oil (HFO)", "Marine Gas Oil (MGO)", "N/A"]
        }
    },
    methods: {
        proceedApplication() {
            this.formData.company_vehicles.total = this.totalNumber
            axios.post("https://carbon-application-flask.nw.r.appspot.com/store_data", Object.assign({current_page_no: this.pageno}, this.formData, {username: this.username, password: this.password})) .then((r) => {
                if(r.data.message === "Data successfully updated") {
                    this.$emit('finish', this.formData)
                } else {
                    alert("Something went wrong, please check your information.")
                }
            }) .catch((e) => {
                console.log(e)
            })
        }
    },
    props: {
        username: null,
        password: null,
        data: null,
        pageno: null,
    },
    computed: {
        isEnergySpendValid() {
            return (
                this.formData.company_vehicles['4x4_suv'].fuel_type &&
                this.formData.company_vehicles['4x4_suv'].number &&
                this.formData.company_vehicles.aeroplane.fuel_type &&
                this.formData.company_vehicles.aeroplane.number &&
                this.formData.company_vehicles.bus_coach.fuel_type &&
                this.formData.company_vehicles.bus_coach.number &&
                this.formData.company_vehicles.company_car.fuel_type &&
                this.formData.company_vehicles.company_car.number &&
                this.formData.company_vehicles.hgv.fuel_type &&
                this.formData.company_vehicles.hgv.number &&
                this.formData.company_vehicles.motorbike.fuel_type &&
                this.formData.company_vehicles.motorbike.number &&
                this.formData.company_vehicles.mpv_minibus.fuel_type &&
                this.formData.company_vehicles.mpv_minibus.number &&
                this.formData.company_vehicles.ship.fuel_type &&
                this.formData.company_vehicles.ship.number &&
                this.formData.company_vehicles.train_engine.fuel_type &&
                this.formData.company_vehicles.train_engine.number &&
                this.formData.company_vehicles.van_lgv.fuel_type &&
                this.formData.company_vehicles.van_lgv.number
            )
        },
        totalNumber() {
            return parseInt(this.formData.company_vehicles['4x4_suv'].number.toString().replace(/,/g, "")) 
            + parseInt(this.formData.company_vehicles.aeroplane.number.toString().replace(/,/g, "")) 
            + parseInt(this.formData.company_vehicles.bus_coach.number.toString().replace(/,/g, "")) 
            + parseInt(this.formData.company_vehicles.company_car.number.toString().replace(/,/g, "")) 
            + parseInt(this.formData.company_vehicles.hgv.number.toString().replace(/,/g, "")) 
            + parseInt(this.formData.company_vehicles.motorbike.number.toString().replace(/,/g, "")) 
            + parseInt(this.formData.company_vehicles.mpv_minibus.number.toString().replace(/,/g, "")) 
            + parseInt(this.formData.company_vehicles.ship.number.toString().replace(/,/g, "")) 
            + parseInt(this.formData.company_vehicles.train_engine.number.toString().replace(/,/g, ""))
            + parseInt(this.formData.company_vehicles.van_lgv.number.toString().replace(/,/g, ""))
        }
    },
    beforeMount() {
        this.formData = {
            'company_vehicles': {
                    '4x4_suv': {'fuel_type': this.data.company_vehicles['4x4_suv'].fuel_type, 'number': this.data.company_vehicles['4x4_suv'].number},
                    'aeroplane': {'fuel_type': this.data.company_vehicles.aeroplane.fuel_type, 'number': this.data.company_vehicles.aeroplane.number},
                    'bus_coach': {'fuel_type': this.data.company_vehicles.bus_coach.fuel_type, 'number': this.data.company_vehicles.bus_coach.number},
                    'company_car': {'fuel_type': this.data.company_vehicles.company_car.fuel_type, 'number': this.data.company_vehicles.company_car.number},
                    'hgv': {'fuel_type': this.data.company_vehicles.hgv.fuel_type, 'number': this.data.company_vehicles.hgv.number},
                    'motorbike': {'fuel_type': this.data.company_vehicles.motorbike.fuel_type, 'number': this.data.company_vehicles.motorbike.number},
                    'mpv_minibus': {'fuel_type': this.data.company_vehicles.mpv_minibus.fuel_type, 'number': this.data.company_vehicles.mpv_minibus.number},
                    'ship': {'fuel_type': this.data.company_vehicles.ship.fuel_type, 'number': this.data.company_vehicles.ship.number},
                    'train_engine': {'fuel_type': this.data.company_vehicles.train_engine.fuel_type, 'number': this.data.company_vehicles.train_engine.number},
                    'van_lgv': {'fuel_type': this.data.company_vehicles.van_lgv.fuel_type, 'number': this.data.company_vehicles.van_lgv.number},
                    'total': this.data.company_vehicles.total
                },
        }
        if(this.formData.company_vehicles['4x4_suv'].fuel_type &&
                this.formData.company_vehicles['4x4_suv'].number &&
                this.formData.company_vehicles.aeroplane.fuel_type &&
                this.formData.company_vehicles.aeroplane.number &&
                this.formData.company_vehicles.bus_coach.fuel_type &&
                this.formData.company_vehicles.bus_coach.number &&
                this.formData.company_vehicles.company_car.fuel_type &&
                this.formData.company_vehicles.company_car.number &&
                this.formData.company_vehicles.hgv.fuel_type &&
                this.formData.company_vehicles.hgv.number &&
                this.formData.company_vehicles.motorbike.fuel_type &&
                this.formData.company_vehicles.motorbike.number &&
                this.formData.company_vehicles.mpv_minibus.fuel_type &&
                this.formData.company_vehicles.mpv_minibus.number &&
                this.formData.company_vehicles.ship.fuel_type &&
                this.formData.company_vehicles.ship.number &&
                this.formData.company_vehicles.train_engine.fuel_type &&
                this.formData.company_vehicles.train_engine.number &&
                this.formData.company_vehicles.van_lgv.fuel_type &&
                this.formData.company_vehicles.van_lgv.number &&
                this.formData.company_vehicles.total) {
            this.$emit('finish', this.formData)
        }
    }
}
</script>