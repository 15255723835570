<template>
    <h1 style="color:#035c34;">Report Information</h1>
          <small style="margin-bottom:2em">Any fields marked with a * are required fields and must be filled out before continuing.</small><br /><br />
          <h4 style="margin-bottom:0.5em;color:#035c34;">Please enter the period of time the following report will cover.* We recommend a date period of at least <i>12 months.</i></h4>
          <label>
            From
            <input v-model="formData.questionnaire_period[0]" style="border: 2px solid #035c34; padding: 0.3em; border-radius: 1em;margin-left:0.5vw" type="date">
          </label>
          <label style="margin-left:2vw">
            To
            <input v-model="formData.questionnaire_period[1]" type="date" style="border: 2px solid #035c34; padding: 0.3em; border-radius: 1em;margin-left:0.5vw">
          </label>
          <br /><br />
          <h4 style="margin-bottom:0.5em;color:#035c34;">Which of the following fields apply to your business?*</h4>
          <v-checkbox label="Business Premises" v-model="formData.business_travel_types" value="Business Premises"></v-checkbox>
          <v-checkbox label="Homeworking" v-model="formData.business_travel_types" value="Homeworking" ></v-checkbox>
          <v-checkbox label="Fleet & Field" v-model="formData.business_travel_types" value="Fleet & Field"></v-checkbox>
          <v-checkbox label="Business Travel" v-model="formData.business_travel_types" value="Business Travel"></v-checkbox>
          <v-btn block size="x-large" color="#7aaa3c" :disabled="!isTimePeriodFormValid" @click="proceedApplication">Next</v-btn>
          <v-btn block size="x-large" @click="$emit('back')">Back</v-btn>
</template>
<script>
import axios from "axios"
export default {
    name: "ReportInformationComponent",
    data() {
        return {
            formData: {
                questionnaire_period: [null, null],
                business_travel_types: []
                
            },
        }
    },
    props: {
        username: null,
        password: null,
        data: null,
        pageno: null,
    },
    methods: {
        proceedApplication() {
            axios.post("https://carbon-application-flask.nw.r.appspot.com/store_data", Object.assign({current_page_no: this.pageno}, this.formData, {username: this.username, password: this.password})) .then((r) => {
                if(r.data.message === "Data successfully updated") {
                    this.$emit('finish', this.formData)
                } else {
                    alert("Something went wrong, please check your information.")
                }
            }) .catch((e) => {
                console.log(e)
            })
        }
    },
    computed: {
        isTimePeriodFormValid() {
            return this.formData.questionnaire_period[0] && this.formData.questionnaire_period[1] && (this.formData.business_travel_types[0]) 
    }
    },
    beforeMount() {
        this.formData = {
            questionnaire_period: [this.data.questionnaire_period[0], this.data.questionnaire_period[1]],
            business_travel_types: []
        }
        if(this.formData.questionnaire_period[0] && this.formData.questionnaire_period[1] && (this.formData.business_travel_types[0])) {
            this.$emit('finish', this.formData)
        }
    }
}
</script>