<template>
  <v-app :theme="apptheme">
    <v-main>
      <Questionnaire/>
    </v-main>
  </v-app>
</template>

<script>
import Questionnaire from './components/QuestionnaireComponent.vue'

export default {
  name: 'App',

  components: {
    Questionnaire,
  },

  data: () => ({
    apptheme:'light'
  }),
}
</script>
<style scoped>
.v-window-transition {
  transition: none !important; /* Force override */
}
</style>