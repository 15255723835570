<template>
    <h1 style="color:#035c34;">{{ splashTitle }}</h1>
    <small>{{ splashDescription }}</small>
    <v-text-field 
      autocomplete="username"
      v-model="formData.email" 
      style="border-radius:1em;margin-bottom:0.1em;" 
      variant="outlined" 
      color="#7aaa3c" 
      label="Email" 
      placeholder="Email" 
      required
      @blur="validateEmail"
    ></v-text-field>
    <p v-if="!isValidEmail && formData.email && !hasEnteredEmail" style="color: red;">Please enter a valid email address.</p>
    <p v-if="axiosError" style="color: red">There was an error verifying your email. Please contact an administrator.</p>
    <span v-if="hasEnteredEmail">
        <span v-if="isExistingUser">
            <v-text-field 
      v-model="password" 
      autocomplete="username"
      style="border-radius:1em;margin-bottom:0.1em;" 
      variant="outlined" 
      color="#7aaa3c" 
      label="Password" 
      type="password"
      placeholder="Password" 
      @keydown.enter="attemptLogin"
      required
    ></v-text-field>
        </span>
        <span v-else>
            <v-text-field 
      v-model="password" 
      autocomplete="username"
      style="border-radius:1em;margin-bottom:0.1em;" 
      variant="outlined" 
      color="#7aaa3c" 
      label="New Password" 
      type="password"
      placeholder="New Password" 
      @keydown.enter="attemptCreateUser"
      required
    ></v-text-field>
    <v-text-field 
      v-model="passwordVal" 
      autocomplete="username"
      style="border-radius:1em;margin-bottom:0.1em;" 
      variant="outlined" 
      color="#7aaa3c" 
      label="Password (again)" 
      type="password"
      placeholder="Password (again)" 
      @keydown.enter="attemptCreateUser"
      required
    ></v-text-field>
        </span>
    </span>
    <span v-if="!hasEnteredEmail">
    <v-btn block @click="checkUser()" :loading="loading" :disabled="!isValidEmail" color="#035c34">Submit</v-btn>
    
    </span>
    <span v-else>
        <v-btn v-if="isExistingUser" :loading="loading" block @click="attemptLogin()" :disabled="!password" color="#035c34">Submit</v-btn>
        <v-btn v-if="!isExistingUser" :loading="loading" block @click="attemptCreateUser()" :disabled="(!password && !passwordVal) && !(password != passwordVal)" color="#035c34">Submit</v-btn>
        <p v-if="!isExistingUser" style="color: red">Passwords must match.</p>
    </span>
    <p style="color:red;padding:2rem;">{{ errorMessage }}</p>
  </template>
  
  <script>
  import axios from "axios"
  export default {
    name: "SignInComponent",
    data() {
      return {
        formData: {
          email: null,
        },
        isExistingUser: false,
        hasEnteredEmail: false,
        isValidEmail: false,
        axiosError: false,
        password: null,
        passwordVal: null,
        errorMessage: "",
        splashTitle: "Welcome",
        splashDescription: "Please enter your email address below to get started.",
        loading: false
      }
    },
    methods: {
      validateEmail() {
        // eslint-disable-next-line
        const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        this.isValidEmail = emailRegex.test(this.formData.email);
      },
      checkUser() {
        this.loading = true
        this.errorMessage = ""
        axios.post("https://carbon-application-flask.nw.r.appspot.com/check_user_exists", {username: this.formData.email}) .then((r) => {
            if(r.data.message === "User exists") {
                this.isExistingUser = true;
                this.splashTitle = "Welcome back!"
                this.splashDescription = "Please enter your password to proceed."
                this.loading = false
            } else {
                this.isExistingUser = false;
                this.splashTitle = "Create account"
                this.splashDescription = "Looks like you're new - create a secure password to proceed."
                this.loading = false
            }
            this.hasEnteredEmail = true;
        }) .catch((e) => {
            console.log(e)
            this.errorMessage = "Unknown error is occured. Please contact an administrator."
            this.loading = false
        })
      },
      retrieveData(callback) {
        axios.post("https://carbon-application-flask.nw.r.appspot.com/retrieve_data", {
            username: this.formData.email,
            password: this.password
        }) .then((r) => {if(r.data.message === "Data successfully updated") {callback(r.data.data)} else {return false}})
        .catch((e) => {console.log(e)})
      },
      async attemptLogin() {
        this.loading = true
        this.errorMessage = ""
        axios.post("https://carbon-application-flask.nw.r.appspot.com/validate_login", {
            username: this.formData.email,
            password: this.password
        }) .then((r) => {
            if(r.data.message === "Valid") {
                let stuff = {
                    username: this.formData.email,
                    password: this.password
                };
                this.loading = false
                this.retrieveData(allData => {
                    this.$emit('finish', [stuff, allData]);
                });
            } else {
                this.axiosError = true;
                this.loading = false
            }
        }) .catch((e) => {
            console.log(e)
            this.loading = false
            this.errorMessage = "Incorrect information. Please try again."
        })
      },
      attemptCreateUser() {
        this.loading = true
        this.errorMessage = ""
        axios.post("https://carbon-application-flask.nw.r.appspot.com/create_user", {
            username: this.formData.email,
            password: this.password
        }) .then((r) => {
            if(r.data.message === "New user created") {
                this.isExistingUser = true
                this.loading = false
            } else {
                this.axiosError = true;
                this.loading = false;
                this.errorMessage = "User already exists. Please try with another email."
            }
        }) .catch((e) => {
            console.log(e)
            this.loading = false
            this.errorMessage = "User already exists. Please try with another email."
        })
      }
    }
  }
  </script>