<template>
    <h1 style="color:#035c34;">Business Travel Information</h1>
          <h4 style="margin-bottom:0.5em;color:#035c34;">Please enter how many times you have travelled for business from the following options and how much it has cost.*</h4><br /><br />
            <v-row>
                <v-col style="height:3.5rem;line-height:3.5rem;">Re-imbursed mileage</v-col>
                <v-col><v-text-field v-mask="['#', '##', '###', '#,###', '##,###', '###,###', '#,###,###', '##,###,###', '###,###,###', '#,###,###,###', '##,###,###,###', '###,###,###,###', '#,###,###,###,###']" masked="true"  v-model="formData.business_travel.reimbursed_milage.number" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="No." placeholder="No." required></v-text-field></v-col>
                <v-col><v-text-field v-model="formData.business_travel.reimbursed_milage.cost" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="£" placeholder="£" required></v-text-field></v-col>
            </v-row>
            <v-row>
                <v-col style="height:3.5rem;line-height:3.5rem;">Flights taken</v-col>
                <v-col><v-text-field v-mask="['#', '##', '###', '#,###', '##,###', '###,###', '#,###,###', '##,###,###', '###,###,###', '#,###,###,###', '##,###,###,###', '###,###,###,###', '#,###,###,###,###']" masked="true"  v-model="formData.business_travel.flights_taken.number" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="No." placeholder="No." required></v-text-field></v-col>
                <v-col><v-text-field v-model="formData.business_travel.flights_taken.cost" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="£" placeholder="£" required></v-text-field></v-col>
            </v-row>
            <v-row>
                <v-col style="height:3.5rem;line-height:3.5rem;">Train journeys</v-col>
                <v-col><v-text-field v-mask="['#', '##', '###', '#,###', '##,###', '###,###', '#,###,###', '##,###,###', '###,###,###', '#,###,###,###', '##,###,###,###', '###,###,###,###', '#,###,###,###,###']" masked="true"  v-model="formData.business_travel.train_journeys.number" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="No." placeholder="No." required></v-text-field></v-col>
                <v-col><v-text-field v-model="formData.business_travel.train_journeys.cost" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="£" placeholder="£" required></v-text-field></v-col>
            </v-row>
            <v-row>
                <v-col style="height:3.5rem;line-height:3.5rem;">Ferry journeys</v-col>
                <v-col><v-text-field v-mask="['#', '##', '###', '#,###', '##,###', '###,###', '#,###,###', '##,###,###', '###,###,###', '#,###,###,###', '##,###,###,###', '###,###,###,###', '#,###,###,###,###']" masked="true"  v-model="formData.business_travel.ferry_journeys.number" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="No." placeholder="No." required></v-text-field></v-col>
                <v-col><v-text-field v-model="formData.business_travel.ferry_journeys.cost" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="£" placeholder="£" required></v-text-field></v-col>
            </v-row>
            <v-row>
                <v-col style="height:3.5rem;line-height:3.5rem;">Bus/coach journeys</v-col>
                <v-col><v-text-field v-mask="['#', '##', '###', '#,###', '##,###', '###,###', '#,###,###', '##,###,###', '###,###,###', '#,###,###,###', '##,###,###,###', '###,###,###,###', '#,###,###,###,###']" masked="true"  v-model="formData.business_travel.bus_coach_journeys.number" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="No." placeholder="No." required></v-text-field></v-col>
                <v-col><v-text-field v-model="formData.business_travel.bus_coach_journeys.cost" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="£" placeholder="£" required></v-text-field></v-col>
            </v-row>
            <v-row>
                <v-col style="height:3.5rem;line-height:3.5rem;">Taxi journeys</v-col>
                <v-col><v-text-field v-mask="['#', '##', '###', '#,###', '##,###', '###,###', '#,###,###', '##,###,###', '###,###,###', '#,###,###,###', '##,###,###,###', '###,###,###,###', '#,###,###,###,###']" masked="true"  v-model="formData.business_travel.taxi_journeys.number" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="No." placeholder="No." required></v-text-field></v-col>
                <v-col><v-text-field v-model="formData.business_travel.taxi_journeys.cost" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="£" placeholder="£" required></v-text-field></v-col>
            </v-row>
            <v-row>
                <v-col style="height:3.5rem;line-height:3.5rem;">Hotel nights</v-col>
                <v-col><v-text-field v-mask="['#', '##', '###', '#,###', '##,###', '###,###', '#,###,###', '##,###,###', '###,###,###', '#,###,###,###', '##,###,###,###', '###,###,###,###', '#,###,###,###,###']" masked="true"  v-model="formData.business_travel.hotel_nights.number" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="No." placeholder="No." required></v-text-field></v-col>
                <v-col><v-text-field v-model="formData.business_travel.hotel_nights.cost" style="border-radius:1em;margin-bottom:0.1em;" variant="outlined" color="#7aaa3c" label="£" placeholder="£" required></v-text-field></v-col>
            </v-row>
    
            <br />
          <v-btn block size="x-large" color="#7aaa3c" :disabled="!isEnergySpendValid" @click="proceedApplication">Finish</v-btn>
          <v-btn block size="x-large" @click="$emit('back')">Back</v-btn>
</template>
<script>
import axios from "axios"
export default {
    name: "EnergySpendInformation",
    data() {
        return {
            formData: {
                'business_travel': {
                'bus_coach_journeys': {'cost': '', 'number': ''},
                'ferry_journeys': {'cost': '', 'number': ''},
                'flights_taken': {'cost': '', 'number': ''},
                'hotel_nights': {'cost': '', 'number': ''},
                'reimbursed_milage': {'cost': '', 'number': ''},
                'taxi_journeys': {'cost': '', 'number': ''},
                'train_journeys': {'cost': '', 'number': ''},
                'total_cost': '',
                'total_kgco2e': '',
                'total_kwh': ''
                },
            },
        }
    },
    props: {
        username: null,
        password: null,
        data: null,
        pageno: null,
    },
    methods: {
        proceedApplication() {
            axios.post("https://carbon-application-flask.nw.r.appspot.com/store_data", Object.assign({current_page_no: this.pageno}, this.formData, {username: this.username, password: this.password})) .then((r) => {
                if(r.data.message === "Data successfully updated") {
                    axios.post("https://carbon-application-flask.nw.r.appspot.com/calculate_totals", {username: this.username, password: this.password}) .then((res) => {
                        
                        this.$emit('finish', res.data.data)
                    }) .catch((err) => {
                        console.error(err)
                    })
                    
                } else {
                    alert("Something went wrong, please check your information.")
                }
            }) .catch((e) => {
                console.log(e)
            })
        }
    },
    beforeMount() {
        this.formData = {
            'business_travel': {
                'bus_coach_journeys': {'cost': this.data.business_travel.bus_coach_journeys.cost, 'number': this.data.business_travel.bus_coach_journeys.number},
                'ferry_journeys': {'cost': this.data.business_travel.ferry_journeys.cost, 'number': this.data.business_travel.ferry_journeys.number},
                'flights_taken': {'cost': this.data.business_travel.flights_taken.cost, 'number': this.data.business_travel.flights_taken.number},
                'hotel_nights': {'cost': this.data.business_travel.hotel_nights.cost, 'number': this.data.business_travel.hotel_nights.number},
                'reimbursed_milage': {'cost': this.data.business_travel.reimbursed_milage.cost, 'number': this.data.business_travel.reimbursed_milage.number},
                'taxi_journeys': {'cost': this.data.business_travel.taxi_journeys.cost, 'number': this.data.business_travel.taxi_journeys.number},
                'train_journeys': {'cost': this.data.business_travel.train_journeys.cost, 'number': this.data.business_travel.train_journeys.number},
                'total_cost': this.data.business_travel.total_cost,
                'total_kgco2e': this.data.business_travel.total_kgco2e,
                'total_kwh': this.data.business_travel.total_kwh
            },
        }
        if(this.formData.business_travel.bus_coach_journeys.cost &&
                this.formData.business_travel.bus_coach_journeys.number &&
                this.formData.business_travel.ferry_journeys.cost &&
                this.formData.business_travel.ferry_journeys.number &&
                this.formData.business_travel.flights_taken.cost &&
                this.formData.business_travel.flights_taken.number &&
                this.formData.business_travel.hotel_nights.cost &&
                this.formData.business_travel.hotel_nights.number &&
                this.formData.business_travel.reimbursed_milage.cost &&
                this.formData.business_travel.reimbursed_milage.number &&
                this.formData.business_travel.taxi_journeys.cost &&
                this.formData.business_travel.taxi_journeys.number &&
                this.formData.business_travel.train_journeys.cost &&
                this.formData.business_travel.train_journeys.number) {
            this.$emit('finish', this.formData)
        }
    },
    computed: {
        isEnergySpendValid() {
            return (
                this.formData.business_travel.bus_coach_journeys.cost &&
                this.formData.business_travel.bus_coach_journeys.number &&
                this.formData.business_travel.ferry_journeys.cost &&
                this.formData.business_travel.ferry_journeys.number &&
                this.formData.business_travel.flights_taken.cost &&
                this.formData.business_travel.flights_taken.number &&
                this.formData.business_travel.hotel_nights.cost &&
                this.formData.business_travel.hotel_nights.number &&
                this.formData.business_travel.reimbursed_milage.cost &&
                this.formData.business_travel.reimbursed_milage.number &&
                this.formData.business_travel.taxi_journeys.cost &&
                this.formData.business_travel.taxi_journeys.number &&
                this.formData.business_travel.train_journeys.cost &&
                this.formData.business_travel.train_journeys.number
            )
        }
    }
}
</script>